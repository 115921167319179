import React, { useRef, useState } from 'react';

import {
  ActionButton,
  Button,
  ButtonGroup,
  ContextualHelp,
  Flex,
  Form,
  InlineAlert,
  Item,
  LabeledValue,
  Picker,
  Switch,
  Text,
  TextArea,
  TextField,
  useDialogContainer,
} from '@adobe/react-spectrum';
import { DOMRefValue, ValidationErrors } from '@react-types/shared';
import Delete from '@spectrum-icons/workflow/Delete';

import { Content, Dialog, Divider, Footer, Heading } from '@/components/UI';
import { Field } from '@/gql';

import { ErrorDialog } from '../errors/ErrorDialog';
import {
  FieldDocument,
  FieldPartsFragment,
  FieldQuery,
  FieldsDocument,
  useCreateFieldMutation,
  useFieldQuery,
  useUpdateFieldMutation,
} from './Fields.g';
import { FieldTypes, FieldUsages } from './constants';

type CreateFieldDialogProps = {
  onCreate: (id: string) => void;
};

type EditFieldDialogProps = {
  fieldId: string;
};

type FieldDialogProps = {
  field: FieldPartsFragment;
  onChange: (changes: Partial<FieldPartsFragment>) => void;
};

export function CreateFieldDialog({ onCreate }: CreateFieldDialogProps) {
  let dialog = useDialogContainer();
  const formRef = useRef<DOMRefValue<HTMLFormElement>>();
  const [name, setName] = useState('');

  const [createField, createFieldResult] = useCreateFieldMutation({
    variables: { input: { name } },
    onCompleted: (data) => {
      if (data.createField.__typename == 'Field') {
        dialog.dismiss();
        onCreate(data.createField.id);
      }
    },
    refetchQueries: [FieldsDocument],
  });

  return (
    <Dialog>
      <Heading>Feld erstellen</Heading>
      <Divider />
      <Content>
        <ErrorDialog results={[createFieldResult]}>
          <Form
            validationBehavior="native"
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              createField();
            }}
          >
            <TextField name="name" label="Name" value={name} isRequired onChange={(name) => setName(name)} />
          </Form>
        </ErrorDialog>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Abbrechen
        </Button>
        <Button variant="accent" type="button" onPress={() => formRef.current.UNSAFE_getDOMNode().requestSubmit()}>
          Speichern
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

export function EditFieldDialog({ fieldId }: EditFieldDialogProps) {
  let dialog = useDialogContainer();
  const { data, loading } = useFieldQuery({ variables: { id: fieldId } });

  const [updateField] = useUpdateFieldMutation({
    onCompleted: () => dialog.dismiss(),
    refetchQueries: [FieldsDocument],
  });

  const onChange = (change: Partial<Field>) => {
    updateField({
      variables: {
        input: {
          id: fieldId,
          ...change,
        },
      },
    });
  };

  return <FieldDialog field={data?.field} onChange={onChange} />;
}

function FieldDialog({ field, onChange }: FieldDialogProps) {
  let dialog = useDialogContainer();

  const formRef = useRef<DOMRefValue<HTMLFormElement>>();
  const [form, setForm] = useState<Partial<Field>>();

  const data = {
    ...(field || {}),
    ...form,
  };

  const changeForm = (data: Partial<Field>) => {
    setForm((form) => ({ ...form, ...data }));
  };

  return (
    <Dialog>
      <Heading>Feld bearbeiten</Heading>
      <Divider />
      <Content>
        <Form
          ref={formRef}
          validationBehavior="native"
          onSubmit={(e) => {
            e.preventDefault();
            onChange(form);
          }}
        >
          {!!data.valueCount && (
            <InlineAlert variant="notice">
              <Heading>Feld bereits in Verwendung</Heading>
              <Content>
                Es wurden bereits Werte für dieses Feld gespeichert. Der Name, Feldname, Typ und Verwendung können nicht
                mehr angepasst werden. Dazu bitte ein neues Feld anlegen.
              </Content>
            </InlineAlert>
          )}
          <Switch isEmphasized isSelected={data.isActive} onChange={(isActive) => changeForm({ isActive })}>
            Feld ist verwendbar.
          </Switch>
          <TextField
            isDisabled={!!data.valueCount}
            isRequired
            name="name"
            label="Name"
            value={data.name}
            onChange={(name) => changeForm({ name })}
          />
          <TextField
            isDisabled={!!data.valueCount}
            isRequired
            name="fieldName"
            label="Feldname"
            value={data.fieldName}
            onChange={(fieldName) => changeForm({ fieldName })}
          />
          <Picker
            isDisabled={!!data.valueCount}
            isRequired
            label="Typ"
            selectedKey={data.type}
            items={FieldTypes}
            onSelectionChange={(type) => changeForm({ type: type.toString() })}
          >
            {(item) => <Item key={item.id}>{item.label}</Item>}
          </Picker>
          <Settings settings={data.settings} type={data.type} onChange={(settings) => changeForm({ settings })} />
          <Picker
            isDisabled={!!data.valueCount}
            isRequired
            label="Verwendung"
            selectedKey={data.usage}
            items={FieldUsages}
            onSelectionChange={(usage) => changeForm({ usage: usage.toString() })}
          >
            {(item) => <Item key={item.id}>{item.label}</Item>}
          </Picker>
          <TextField
            name="group"
            label="Gruppe"
            contextualHelp={
              <ContextualHelp variant="help">
                <Heading>Gruppe</Heading>
                <Content>
                  <Text>Wird zur Gruppierung während der Darstellung der Felder z.B.: im Mitarbeiter verwendet.</Text>
                </Content>
              </ContextualHelp>
            }
            value={data.group}
            onChange={(group) => changeForm({ group })}
          />
          <TextArea
            name="description"
            label="Beschreibung"
            value={data.description}
            onChange={(description) => changeForm({ description })}
          ></TextArea>
          <TextArea
            name="description"
            label="Hilfetext"
            value={data.helpText}
            onChange={(helpText) => changeForm({ helpText })}
          ></TextArea>
        </Form>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={dialog.dismiss}>
          Abbrechen
        </Button>
        <Button variant="accent" onPress={() => formRef.current.UNSAFE_getDOMNode().requestSubmit()}>
          Speichern
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

type SettingsProps = {
  settings: any;
  type: string;
  onChange: (settings: any) => void;
};

function Settings({ settings, type, onChange }: SettingsProps) {
  const [dataOverrides, setData] = useState({});

  const data = {
    ...(settings || {}),
    ...dataOverrides,
  };

  switch (type) {
    case 'SELECT':
      const selectData: [{ value: string; label: string }] = Array.isArray(data?.items) ? data.items : [];

      const deleteSelectData = (index: number) => {
        const items = selectData.slice();
        items.splice(index, 1);

        const newData = {
          ...data,
          items,
        };

        setData(newData);
        onChange(newData);
      };

      const updateSelectData = (update: any, index: number) => {
        const item = { ...selectData[index], ...update };
        const items = selectData.slice();
        items.splice(index, 1, item);

        const newData = {
          ...data,
          items,
        };

        setData(newData);
        onChange(newData);
      };

      const updateData = (change: any) => {
        const newData = {
          ...data,
          ...change,
        };
        setData(newData);
        onChange(newData);
      };

      return (
        <>
          <Switch
            isSelected={!!data.allowsCustomValue}
            onChange={(allowsCustomValue) => updateData({ allowsCustomValue })}
          >
            benutzerdefinierte Werte erlauben
          </Switch>
          {selectData.map((item: any, index: number) => (
            <Flex gap="size-100" key={`item-${index}`} width={'100%'}>
              <TextField
                label={index == 0 && 'Schlüssel'}
                width={'100%'}
                isRequired
                value={item.value}
                onChange={(value) => updateSelectData({ value }, index)}
              />
              <TextField
                label={index == 0 && 'Wert'}
                width={'100%'}
                isRequired
                value={item.label}
                onChange={(label) => updateSelectData({ label }, index)}
              />
              <ActionButton alignSelf={'flex-end'} onPress={() => deleteSelectData(index)}>
                <Delete />
              </ActionButton>
            </Flex>
          ))}
          <ActionButton
            onPress={() =>
              setData((data: any) => ({
                ...data,
                items: [...selectData, { value: '', label: '' }],
              }))
            }
          >
            Element hinzufügen
          </ActionButton>
        </>
      );
    default:
      return (
        <InlineAlert variant="info">
          <Heading>Keine weiteren Einstellungen</Heading>
          <Content>Dieser Typ hat keine weiteren Einstellungen.</Content>
        </InlineAlert>
      );
  }
}
