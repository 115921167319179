import * as Types from '../../gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MandatorGateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MandatorGateQuery = { __typename?: 'RootQuery', me?: { __typename?: 'User', id: string, activeMandator?: { __typename?: 'Mandator', id: string, name: string } | null, mandators: Array<{ __typename?: 'Mandator', id: string, name: string }> } | null };


export const MandatorGateDocument = gql`
    query MandatorGate {
  me {
    id
    activeMandator {
      id
      name
    }
    mandators {
      id
      name
    }
  }
}
    `;

/**
 * __useMandatorGateQuery__
 *
 * To run a query within a React component, call `useMandatorGateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandatorGateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandatorGateQuery({
 *   variables: {
 *   },
 * });
 */
export function useMandatorGateQuery(baseOptions?: Apollo.QueryHookOptions<MandatorGateQuery, MandatorGateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MandatorGateQuery, MandatorGateQueryVariables>(MandatorGateDocument, options);
      }
export function useMandatorGateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MandatorGateQuery, MandatorGateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MandatorGateQuery, MandatorGateQueryVariables>(MandatorGateDocument, options);
        }
export type MandatorGateQueryHookResult = ReturnType<typeof useMandatorGateQuery>;
export type MandatorGateLazyQueryHookResult = ReturnType<typeof useMandatorGateLazyQuery>;
export type MandatorGateQueryResult = Apollo.QueryResult<MandatorGateQuery, MandatorGateQueryVariables>;