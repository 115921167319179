import React from 'react';

import {
    SpectrumStatusLightProps,
    StatusLight as StatusLightSpectrum,
} from '@adobe/react-spectrum';

interface StatusLightProps extends SpectrumStatusLightProps {}

export const StatusLight: React.FC<StatusLightProps> = (props) => (
    <StatusLightSpectrum {...props} />
);
