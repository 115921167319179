import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  AlertDialog,
  DialogContainer,
  Form,
  Item,
  SearchField,
  Switch,
  Text,
  TextArea,
  TextField,
} from '@adobe/react-spectrum';
import { Key } from '@react-types/shared';
import Cancel from '@spectrum-icons/workflow/Cancel';
import Delete from '@spectrum-icons/workflow/Delete';
import SaveTo from '@spectrum-icons/workflow/SaveTo';
import { update } from 'cypress/types/lodash';

import { Page, PageHeader } from '@/components/UI';

import { useCloseTab } from '../app-tabs/hooks';
import {
  SubcontractorQueryResult,
  useDeleteSubcontractorMutation,
  useSubcontractorQuery,
  useUpdateSubcontractorMutation,
} from './Subcontractor.g';

type Subcontractor = SubcontractorQueryResult['data']['subcontractor'];

type Props = {};

export const Subcontractor = (props: Props) => {
  const { id } = useParams();
  const closeTab = useCloseTab();

  const [form, setForm] = useState<Partial<Subcontractor>>({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const query = useSubcontractorQuery({ variables: { id } });

  const [updateSubcontractor] = useUpdateSubcontractorMutation({
    variables: {
      input: {
        id,
        ...form,
      },
    },
    onCompleted: () => setForm({}),
  });

  const [deleteSubcontractor] = useDeleteSubcontractorMutation({
    variables: { input: { id } },
    onCompleted: () => closeTab(),
  });

  const subcontractor: Subcontractor | undefined = query.data
    ? {
        ...query.data?.subcontractor,
        ...form,
      }
    : undefined;

  const onAction = (action: Key) => {
    if (action == 'reset') setForm({});
    else if (action == 'save') updateSubcontractor();
    else if (action == 'delete') setShowDeleteConfirm(true);
  };
  const onChange = (change: Partial<Subcontractor>) => setForm((form) => ({ ...form, ...change }));

  return (
    <Page title={subcontractor?.name} icon="page" height="100%" loading={query.loading}>
      <PageHeader disabledActions={Object.keys(form).length > 0 ? [] : ['save', 'reset']} onAction={onAction}>
        <Item key={'save'}>
          <SaveTo />
          <Text>speichern</Text>
        </Item>

        <Item key={'reset'}>
          <Cancel />
          <Text>zurücksetzen</Text>
        </Item>

        <Item key={'delete'}>
          <Delete />
          <Text>löschen</Text>
        </Item>
      </PageHeader>
      <DialogContainer onDismiss={() => setShowDeleteConfirm(false)}>
        {showDeleteConfirm && (
          <AlertDialog
            title={`${subcontractor.name} löschen?`}
            variant="destructive"
            primaryActionLabel="Löschen"
            cancelLabel="Abbrechen"
            onPrimaryAction={() => deleteSubcontractor()}
          >
            Möchten Sie den Subunternehmer wirklich löschen? <br />
            Sofern Daten mit diesem Subunternehmer verknüpft sind wird dieser nur deaktiviert.
          </AlertDialog>
        )}
      </DialogContainer>
      {subcontractor && <SubcontractorForm subcontractor={subcontractor} onChange={onChange} />}
    </Page>
  );
};

type SubcontractorFormProps = {
  subcontractor: Subcontractor;
  onChange: (change: Partial<Subcontractor>) => void;
};

function SubcontractorForm({ subcontractor, onChange }: SubcontractorFormProps) {
  return (
    <Form>
      <TextField label="Name" value={subcontractor.name} onChange={(name) => onChange({ name })} />
      <TextArea label="Adresse" value={subcontractor.address} onChange={(address) => onChange({ address })} />
      <Switch isEmphasized isSelected={subcontractor.active} onChange={(active) => onChange({ active })}>
        Aktiv
      </Switch>
    </Form>
  );
}
