export const FieldTypes = [
    { id: 'TEXT', label: 'Text' },
    { id: 'DATE', label: 'Datum' },
    { id: 'DATETIME', label: 'Datum und Zeit' },
    { id: 'TIME', label: 'Zeit' },
    { id: 'BOOL', label: 'Ja / Nein' },
    { id: 'INTEGER', label: 'Zahl' },
    { id: 'DECIMAL', label: 'Dezimalzahl' },
    { id: 'SELECT', label: 'Auswahl' },
];

export const FieldTypesMap = Object.fromEntries(
    FieldTypes.map((f) => [f.id, f.label]),
);

export const FieldUsages = [
    { id: 'EMPLOYEE', label: 'Mitarbeiter' },
    { id: 'POSITION', label: 'Position' },
    { id: 'PLAN', label: 'Plan' },
];

export const FieldUsagesMap = Object.fromEntries(
    FieldUsages.map((f) => [f.id, f.label]),
);
