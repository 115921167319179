import * as React from 'react';
import { useState } from 'react';

import {
  ActionButton,
  Cell,
  Column,
  DialogContainer,
  Row,
  SearchField,
  Selection,
  TableBody,
  TableHeader,
  TableView,
  useFilter,
} from '@adobe/react-spectrum';
import { QueryResult } from '@apollo/client';
import { Key, LoadingState } from '@react-types/shared';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import Add from '@spectrum-icons/workflow/Add';
import Copy from '@spectrum-icons/workflow/Copy';
import Delete from '@spectrum-icons/workflow/Delete';

import {
  ActionBar,
  ActionBarContainer,
  Flex,
  Header,
  Heading,
  IllustratedMessage,
  Item,
  PageHeader,
  StatusLight,
  Text,
} from '@/components/UI';
import { Page } from '@/components/UI/page';

import { CreateSubcontractorDialog } from './CreateSubcontractorDialog';
import { SubcontractorQueryResult, useSubcontractorsQuery } from './Subcontractor.g';

interface RowData {
  contractorName: string;
  contactName: string;
  status: string;
}

function renderEmptyState() {
  return (
    <IllustratedMessage>
      <NotFound />
      <Heading>Keine Subunternehmer gefunden</Heading>
    </IllustratedMessage>
  );
}

function loadingStateFromQuery(query: QueryResult<unknown, unknown>): LoadingState {
  if (query.loading) {
    return query.data !== undefined ? 'loadingMore' : 'loading';
  }

  if (query.error) {
    return 'error';
  }

  return 'idle';
}

export const SubcontractorsList: React.FC<React.PropsWithoutRef<{}>> = () => {
  const query = useSubcontractorsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [search, setSearch] = useState('');
  let { contains } = useFilter({
    sensitivity: 'base',
  });

  const [dialog, setDialog] = useState('');

  const subcontractors = (query.data?.subcontractors || []).filter((sub) =>
    contains(`${sub.name} ${sub.address}`, search)
  );

  return (
    <Page title="Subunternehmer" icon="people" height="100%" loading={query.loading}>
      <PageHeader
        onAction={(action) => setDialog(action.toString())}
        search={<SearchField value={search} onChange={setSearch} />}
      >
        <Item key={'create'}>
          <Add />
          <Text>Subunternehmer</Text>
        </Item>
      </PageHeader>
      <DialogContainer onDismiss={() => setDialog('')}>
        {dialog == 'create' && <CreateSubcontractorDialog />}
      </DialogContainer>
      <TableView renderEmptyState={renderEmptyState}>
        <TableHeader>
          <Column>Name</Column>
          <Column>Adresse</Column>
          <Column>Aktiv</Column>
        </TableHeader>
        <TableBody items={subcontractors} loadingState={loadingStateFromQuery(query)}>
          {(row) => (
            <Row key={row.id} href={`/subcontractors/${row.id}`}>
              <Cell>{row.name}</Cell>
              <Cell>{row.address}</Cell>
              <Cell>
                {row.active ? (
                  <StatusLight variant="positive">Aktiv</StatusLight>
                ) : (
                  <StatusLight variant="negative">Inaktiv</StatusLight>
                )}
              </Cell>
            </Row>
          )}
        </TableBody>
      </TableView>
    </Page>
  );
};
