// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme__pep--aAZQO {
    --spectrum-dialog-padding: var(--spectrum-global-dimension-static-300);
}
`, "",{"version":3,"sources":["webpack://./src/theme.css"],"names":[],"mappings":"AAAA;IACI,sEAAsE;AAC1E","sourcesContent":[".pep {\n    --spectrum-dialog-padding: var(--spectrum-global-dimension-static-300);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pep": `theme__pep--aAZQO`
};
export default ___CSS_LOADER_EXPORT___;
