import React, { useMemo, useState } from 'react';

import { ActionBar, ActionBarContainer, StatusLight } from '@adobe/react-spectrum';
import { Selection } from '@react-types/shared';
import Add from '@spectrum-icons/workflow/Add';
import Delete from '@spectrum-icons/workflow/Delete';

import { Cell, Column, Item, Page, PageHeader, Row, TableBody, TableHeader, TableView, Text } from '@/components/UI';

export function FunctionList() {
  const [selection, setSelection] = useState<Selection>();

  const disabledActions = useMemo(() => {
    const actions: string[] = ['delete'];
    if (selection == 'all' || selection?.size > 0) {
      actions.splice(actions.indexOf('delete'), 1);
    }

    return actions;
  }, [selection]);

  return (
    <Page title="Funktionen" icon="list">
      <PageHeader disabledActions={disabledActions}>
        <Item key="create">
          <Add />
          <Text>Erstellen</Text>
        </Item>
        <Item key="delete">
          <Delete />
          <Text>Löschen</Text>
        </Item>
      </PageHeader>
      <TableView selectionMode="multiple" onSelectionChange={(keys) => setSelection(keys)} selectedKeys={selection}>
        <TableHeader>
          <Column>Name</Column>
          <Column>Status</Column>
        </TableHeader>
        <TableBody>
          <Row>
            <Cell>Test</Cell>
            <Cell>
              <StatusLight variant="positive">In Verwendung</StatusLight>
            </Cell>
          </Row>
        </TableBody>
      </TableView>
    </Page>
  );
}
