import * as Types from '../../gql';

import { gql } from '@apollo/client';
import { FieldPartsFragmentDoc } from '../fields/Fields.g';
import { OperationInfoPartsFragmentDoc } from '../errors/Errros.g';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlansQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PlansQuery = { __typename?: 'RootQuery', plans: Array<{ __typename?: 'Plan', id: string, name: string, start: any, end: any, status: string }> };

export type PositionNotePartsFragment = { __typename?: 'Position', id: string, note: string, noteIsImportant: boolean };

export type PositionNoteQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type PositionNoteQuery = { __typename?: 'RootQuery', position: { __typename?: 'Position', id: string, note: string, noteIsImportant: boolean } };

export type PositionPartsFragment = { __typename?: 'Position', id: string, date: any, start: any, end: any, status: string, note: string, noteIsImportant: boolean, fieldValues: Array<{ __typename?: 'FieldValue', fieldId: string, data: any }> };

export type PlanJobsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PlanJobsQuery = { __typename?: 'RootQuery', jobs: Array<string> };

export type PlanPartsFragment = { __typename?: 'Plan', id: string, name: string, start: any, end: any, status: string, hint: string, fieldValues: Array<{ __typename?: 'FieldValue', fieldId: string, data: any }> };

export type PlanQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  date?: Types.InputMaybe<Types.Scalars['Date']>;
}>;


export type PlanQuery = { __typename?: 'RootQuery', plan: { __typename?: 'Plan', id: string, name: string, start: any, end: any, status: string, hint: string, positions: Array<{ __typename?: 'Position', id: string, date: any, start: any, end: any, status: string, note: string, noteIsImportant: boolean, fieldValues: Array<{ __typename?: 'FieldValue', fieldId: string, data: any }> }>, fieldValues: Array<{ __typename?: 'FieldValue', fieldId: string, data: any }> }, positionFields: Array<{ __typename?: 'Field', id: string, type: string, name: string, fieldName: string, helpText: string, order: number, isActive: boolean, group: string, usage: string, description: string, settings: any, valueCount: number }>, planFields: Array<{ __typename?: 'Field', id: string, type: string, name: string, fieldName: string, helpText: string, order: number, isActive: boolean, group: string, usage: string, description: string, settings: any, valueCount: number }> };

export type UpdatePlanMutationVariables = Types.Exact<{
  input: Types.UpdatePlanInput;
}>;


export type UpdatePlanMutation = { __typename?: 'RootMutation', updatePlan: { __typename?: 'OperationInfo', messages: Array<{ __typename?: 'OperationMessage', kind: Types.OperationMessageKind, message: string, field?: string | null, code?: string | null }> } };

export type CopyPositionMutationVariables = Types.Exact<{
  input: Types.CopyPositionInput;
}>;


export type CopyPositionMutation = { __typename?: 'RootMutation', copyPosition: { __typename?: 'OperationInfo' } | { __typename?: 'Plan', id: string } };

export type CreatePositionMutationVariables = Types.Exact<{
  input: Types.CreatePositionInput;
}>;


export type CreatePositionMutation = { __typename?: 'RootMutation', createPosition: { __typename?: 'OperationInfo' } | { __typename?: 'Position', id: string } };

export type UpdatePositionMutationVariables = Types.Exact<{
  input: Types.UpdatePositionInput;
}>;


export type UpdatePositionMutation = { __typename?: 'RootMutation', updatePosition: { __typename?: 'OperationInfo', messages: Array<{ __typename?: 'OperationMessage', kind: Types.OperationMessageKind, message: string, field?: string | null, code?: string | null }> } | { __typename?: 'Position', id: string, date: any, start: any, end: any, status: string, note: string, noteIsImportant: boolean, fieldValues: Array<{ __typename?: 'FieldValue', fieldId: string, data: any }> } };

export type DeletePositionMutationVariables = Types.Exact<{
  input: Types.DeletePositionInput;
}>;


export type DeletePositionMutation = { __typename?: 'RootMutation', deletePosition: { __typename?: 'OperationInfo' } | { __typename?: 'Plan', id: string } };

export type CreatePlanMutationVariables = Types.Exact<{
  input: Types.CreatePlanInput;
}>;


export type CreatePlanMutation = { __typename?: 'RootMutation', createPlan: { __typename?: 'OperationInfo' } | { __typename?: 'Plan', id: string } };

export const PositionNotePartsFragmentDoc = gql`
    fragment PositionNoteParts on Position {
  id
  note
  noteIsImportant
}
    `;
export const PositionPartsFragmentDoc = gql`
    fragment PositionParts on Position {
  id
  date
  start
  end
  status
  fieldValues {
    fieldId
    data
  }
  ...PositionNoteParts
}
    ${PositionNotePartsFragmentDoc}`;
export const PlanPartsFragmentDoc = gql`
    fragment PlanParts on Plan {
  id
  name
  start
  end
  status
  hint
  fieldValues {
    fieldId
    data
  }
}
    `;
export const PlansDocument = gql`
    query Plans {
  plans {
    id
    name
    start
    end
    status
  }
}
    `;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const PositionNoteDocument = gql`
    query PositionNote($id: ID!) {
  position(id: $id) {
    ...PositionNoteParts
  }
}
    ${PositionNotePartsFragmentDoc}`;

/**
 * __usePositionNoteQuery__
 *
 * To run a query within a React component, call `usePositionNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePositionNoteQuery(baseOptions: Apollo.QueryHookOptions<PositionNoteQuery, PositionNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PositionNoteQuery, PositionNoteQueryVariables>(PositionNoteDocument, options);
      }
export function usePositionNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PositionNoteQuery, PositionNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PositionNoteQuery, PositionNoteQueryVariables>(PositionNoteDocument, options);
        }
export type PositionNoteQueryHookResult = ReturnType<typeof usePositionNoteQuery>;
export type PositionNoteLazyQueryHookResult = ReturnType<typeof usePositionNoteLazyQuery>;
export type PositionNoteQueryResult = Apollo.QueryResult<PositionNoteQuery, PositionNoteQueryVariables>;
export const PlanJobsDocument = gql`
    query PlanJobs {
  jobs
}
    `;

/**
 * __usePlanJobsQuery__
 *
 * To run a query within a React component, call `usePlanJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanJobsQuery(baseOptions?: Apollo.QueryHookOptions<PlanJobsQuery, PlanJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanJobsQuery, PlanJobsQueryVariables>(PlanJobsDocument, options);
      }
export function usePlanJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanJobsQuery, PlanJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanJobsQuery, PlanJobsQueryVariables>(PlanJobsDocument, options);
        }
export type PlanJobsQueryHookResult = ReturnType<typeof usePlanJobsQuery>;
export type PlanJobsLazyQueryHookResult = ReturnType<typeof usePlanJobsLazyQuery>;
export type PlanJobsQueryResult = Apollo.QueryResult<PlanJobsQuery, PlanJobsQueryVariables>;
export const PlanDocument = gql`
    query Plan($id: ID!, $date: Date) {
  plan(id: $id) {
    ...PlanParts
    positions(date: $date) {
      ...PositionParts
    }
  }
  positionFields: fields(usage: "POSITION") {
    ...FieldParts
  }
  planFields: fields(usage: "PLAN") {
    ...FieldParts
  }
}
    ${PlanPartsFragmentDoc}
${PositionPartsFragmentDoc}
${FieldPartsFragmentDoc}`;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function usePlanQuery(baseOptions: Apollo.QueryHookOptions<PlanQuery, PlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
      }
export function usePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanQuery, PlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
        }
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export type PlanQueryResult = Apollo.QueryResult<PlanQuery, PlanQueryVariables>;
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($input: UpdatePlanInput!) {
  updatePlan(input: $input) {
    ...OperationInfoParts
  }
}
    ${OperationInfoPartsFragmentDoc}`;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const CopyPositionDocument = gql`
    mutation CopyPosition($input: CopyPositionInput!) {
  copyPosition(input: $input) {
    ... on Plan {
      id
    }
  }
}
    `;
export type CopyPositionMutationFn = Apollo.MutationFunction<CopyPositionMutation, CopyPositionMutationVariables>;

/**
 * __useCopyPositionMutation__
 *
 * To run a mutation, you first call `useCopyPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyPositionMutation, { data, loading, error }] = useCopyPositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyPositionMutation(baseOptions?: Apollo.MutationHookOptions<CopyPositionMutation, CopyPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyPositionMutation, CopyPositionMutationVariables>(CopyPositionDocument, options);
      }
export type CopyPositionMutationHookResult = ReturnType<typeof useCopyPositionMutation>;
export type CopyPositionMutationResult = Apollo.MutationResult<CopyPositionMutation>;
export type CopyPositionMutationOptions = Apollo.BaseMutationOptions<CopyPositionMutation, CopyPositionMutationVariables>;
export const CreatePositionDocument = gql`
    mutation CreatePosition($input: CreatePositionInput!) {
  createPosition(input: $input) {
    ... on Position {
      id
    }
  }
}
    `;
export type CreatePositionMutationFn = Apollo.MutationFunction<CreatePositionMutation, CreatePositionMutationVariables>;

/**
 * __useCreatePositionMutation__
 *
 * To run a mutation, you first call `useCreatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPositionMutation, { data, loading, error }] = useCreatePositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePositionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePositionMutation, CreatePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePositionMutation, CreatePositionMutationVariables>(CreatePositionDocument, options);
      }
export type CreatePositionMutationHookResult = ReturnType<typeof useCreatePositionMutation>;
export type CreatePositionMutationResult = Apollo.MutationResult<CreatePositionMutation>;
export type CreatePositionMutationOptions = Apollo.BaseMutationOptions<CreatePositionMutation, CreatePositionMutationVariables>;
export const UpdatePositionDocument = gql`
    mutation UpdatePosition($input: UpdatePositionInput!) {
  updatePosition(input: $input) {
    ...PositionParts
    ...OperationInfoParts
  }
}
    ${PositionPartsFragmentDoc}
${OperationInfoPartsFragmentDoc}`;
export type UpdatePositionMutationFn = Apollo.MutationFunction<UpdatePositionMutation, UpdatePositionMutationVariables>;

/**
 * __useUpdatePositionMutation__
 *
 * To run a mutation, you first call `useUpdatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionMutation, { data, loading, error }] = useUpdatePositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePositionMutation, UpdatePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePositionMutation, UpdatePositionMutationVariables>(UpdatePositionDocument, options);
      }
export type UpdatePositionMutationHookResult = ReturnType<typeof useUpdatePositionMutation>;
export type UpdatePositionMutationResult = Apollo.MutationResult<UpdatePositionMutation>;
export type UpdatePositionMutationOptions = Apollo.BaseMutationOptions<UpdatePositionMutation, UpdatePositionMutationVariables>;
export const DeletePositionDocument = gql`
    mutation DeletePosition($input: DeletePositionInput!) {
  deletePosition(input: $input) {
    ... on Plan {
      id
    }
  }
}
    `;
export type DeletePositionMutationFn = Apollo.MutationFunction<DeletePositionMutation, DeletePositionMutationVariables>;

/**
 * __useDeletePositionMutation__
 *
 * To run a mutation, you first call `useDeletePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePositionMutation, { data, loading, error }] = useDeletePositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePositionMutation(baseOptions?: Apollo.MutationHookOptions<DeletePositionMutation, DeletePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePositionMutation, DeletePositionMutationVariables>(DeletePositionDocument, options);
      }
export type DeletePositionMutationHookResult = ReturnType<typeof useDeletePositionMutation>;
export type DeletePositionMutationResult = Apollo.MutationResult<DeletePositionMutation>;
export type DeletePositionMutationOptions = Apollo.BaseMutationOptions<DeletePositionMutation, DeletePositionMutationVariables>;
export const CreatePlanDocument = gql`
    mutation CreatePlan($input: CreatePlanInput!) {
  createPlan(input: $input) {
    ... on Plan {
      id
    }
  }
}
    `;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options);
      }
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;