import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  ActionMenu,
  Button,
  ButtonGroup,
  Content,
  DateRangePicker,
  Dialog,
  Divider,
  Flex,
  Form,
  Heading,
  Item,
  LabeledValue,
  ListView,
  SpectrumListViewProps,
  Switch,
  Text,
  TextArea,
  TextField,
  View,
  useDialogContainer,
  useDragAndDrop,
  useListData,
} from '@adobe/react-spectrum';
import { parseDate } from '@internationalized/date';
import { StyleProps } from '@react-types/shared';
import { DOMRefValue } from '@react-types/shared';
import { update } from 'cypress/types/lodash';

import { UpdatePlanFieldInput, UpdatePlanInput } from '@/gql';

import { FieldData, FieldEditor } from '../fields/FieldEditor';
import { PlanQueryResult, usePlanQuery, useUpdatePlanMutation } from './Plans.g';

type EditPlanDialogProps = {
  id: string;
};

export function EditPlanDialog({ id }: EditPlanDialogProps) {
  const ref = useRef<DOMRefValue<HTMLFormElement>>();
  const [form, setForm] = useState<Partial<UpdatePlanInput>>({});
  const container = useDialogContainer();
  const query = usePlanQuery({ variables: { id }, fetchPolicy: 'cache-and-network' });

  const [updatePlan] = useUpdatePlanMutation({
    variables: {
      input: {
        id,
        ...form,
      },
    },
    onCompleted: () => container.dismiss(),
  });

  const [fieldChanges, setFieldChanges] = useState({});

  const fieldValues: { [fieldId: string]: FieldData } = useMemo(
    () => ({
      ...Object.fromEntries(
        (query.data?.plan.fieldValues || []).map((fieldValue) => [fieldValue.fieldId, fieldValue.data])
      ),
      ...fieldChanges,
    }),
    [query.data?.plan, fieldChanges]
  );

  const plan = {
    ...(query.data && query.data.plan),
    ...form,
  };

  const positionFields = (query.data || query.previousData)?.positionFields;
  const planFields = (query.data || query.previousData)?.planFields;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updatePlan();
  };

  return (
    <Dialog size="M">
      <Heading>Plan bearbeiten</Heading>
      <Divider />
      <Content>
        <View flex="1 1 100%">
          <Form ref={ref} onSubmit={onSubmit} validationBehavior="native" marginBottom={'size-200'}>
            <TextField
              label="Name"
              isRequired
              autoFocus
              value={plan.name}
              onChange={(name) => setForm((form) => ({ ...form, name }))}
            />
            <DateRangePicker
              label="Zeitraum"
              isRequired
              value={{ start: plan.start && parseDate(plan.start), end: plan.end && parseDate(plan.end) }}
              onChange={(range) =>
                setForm((form) => ({ ...form, start: range.start.toString(), end: range.end.toString() }))
              }
            />
            <TextArea label="Hinweis" value={plan.hint} onChange={(hint) => setForm((form) => ({ ...form, hint }))} />
            <FieldEditor
              fields={planFields || []}
              fieldData={fieldValues}
              onChange={(field, data) => setFieldChanges((changes) => ({ ...changes, [field.id]: data }))}
            />
          </Form>
        </View>
        <View flex="1 1 100%">
          <Heading level={4}>Positionsfelder</Heading>
          <PositionFieldListView
            width={'100%'}
            key={positionFields?.length > 0 ? 'filled' : 'empty'}
            fields={positionFields}
          />
        </View>
      </Content>
      <ButtonGroup isDisabled={query.loading}>
        <Button variant="secondary" onPress={container.dismiss}>
          Abbrechen
        </Button>
        <Button variant="accent" onPress={() => ref.current.UNSAFE_getDOMNode().requestSubmit()}>
          Speichern
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

type PositionFieldListViewProps = {
  fields: PlanQueryResult['data']['positionFields'];
} & StyleProps;

function PositionFieldListView({ fields, ...listViewProps }: PositionFieldListViewProps) {
  let list = useListData({
    initialItems: fields || [],
  });

  let { dragAndDropHooks } = useDragAndDrop({
    getItems(keys) {
      return [...Array.from(keys)].map((key) => {
        let item = list.getItem(key);
        return {
          'plan-field': JSON.stringify(item),
          'text/plain': item.name,
        };
      });
    },
    acceptedDragTypes: ['plan-field'],
    onReorder: async (e) => {
      let { keys, target } = e;

      if (target.dropPosition === 'before') {
        list.moveBefore(target.key, Array.from(keys));
      } else if (target.dropPosition === 'after') {
        list.moveAfter(target.key, Array.from(keys));
      }
    },
    getAllowedDropOperations: () => ['move'],
  });

  return (
    <ListView items={list.items} selectionMode="none" dragAndDropHooks={dragAndDropHooks} {...listViewProps}>
      {(field) => (
        <Item key={field.id}>
          <Switch isEmphasized />
          <Text>{field.name}</Text>
          <ActionMenu>
            <Item key="group">Gruppieren</Item>
          </ActionMenu>
        </Item>
      )}
    </ListView>
  );
}
