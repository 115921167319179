import * as React from 'react';
import { useContext } from 'react';

import { Content, Dialog, Heading, Item, ListView } from '@/components/UI';

import { MandatorContext } from './MandatorProvider';

type MandatorSelectDialog = {
    isDismissable?: boolean;
    onSelected?: () => void;
};

export const MandatorSelectDialog: React.FC<
    React.PropsWithoutRef<MandatorSelectDialog>
> = ({ isDismissable, onSelected }) => {
    const { setMandator, selected, mandators } = useContext(MandatorContext);

    return (
        <Dialog size="S" isDismissable={isDismissable}>
            <Heading>Mandant auswählen</Heading>
            <Content>
                <ListView
                    marginTop={'size-200'}
                    density="spacious"
                    selectionMode="single"
                    onSelectionChange={(keys) => {
                        setMandator(
                            keys === 'all' ? null : keys.values().next().value,
                        );
                        onSelected && onSelected();
                    }}
                    selectionStyle="highlight"
                    selectedKeys={selected ? [selected.id] : []}
                    items={mandators}
                >
                    {(item) => <Item key={item.id}>{item.name}</Item>}
                </ListView>
            </Content>
        </Dialog>
    );
};
