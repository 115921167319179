import * as React from 'react';

import { Flex, View, ViewProps } from '@adobe/react-spectrum';

import { useTitle } from '@/features/app-tabs';
import { TabIcon } from '@/features/app-tabs/TabIcons';

import styles from './Page.css';

type PageProps = {
  title: string;
  icon?: TabIcon;
  loading?: boolean;
} & ViewProps<5>;

type PageContextProps = {
  icon: TabIcon;
  title: string;
  loading: boolean;
};

export const PageContext = React.createContext<PageContextProps>({
  icon: 'page',
  title: '...',
  loading: true,
});

export const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ title, icon, children, loading, ...props }) => {
  useTitle(title, icon);
  return (
    <PageContext.Provider value={{ title, icon, loading }}>
      <View {...props}>
        <Flex direction="column" height="100%" gap={'size-100'}>
          {children}
        </Flex>
      </View>
    </PageContext.Provider>
  );
};
