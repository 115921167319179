import Calendar from '@spectrum-icons/workflow/Calendar';
import Home from '@spectrum-icons/workflow/Home';
import Key from '@spectrum-icons/workflow/Key';
import Money from '@spectrum-icons/workflow/Money';
import PeopleGroup from '@spectrum-icons/workflow/PeopleGroup';
import Settings from '@spectrum-icons/workflow/Settings';
import ViewList from '@spectrum-icons/workflow/ViewList';
import WebPage from '@spectrum-icons/workflow/WebPage';

export const TabIcons = {
    calendar: Calendar,
    home: Home,
    list: ViewList,
    people: PeopleGroup,
    settings: Settings,
    money: Money,
    page: WebPage,
    key: Key,
};

type TabIconName<T extends Object> = keyof T;
export type TabIcon = TabIconName<typeof TabIcons>;
