// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --spectrum-dialog-padding: var(--spectrum-global-dimension-size-200);
}

body,
html {
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

body > div {
    height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;IACI,oEAAoE;AACxE;;AAEA;;IAEI,YAAY;IACZ,UAAU;IACV,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[":root {\n    --spectrum-dialog-padding: var(--spectrum-global-dimension-size-200);\n}\n\nbody,\nhtml {\n    height: 100%;\n    padding: 0;\n    margin: 0;\n    overflow: hidden;\n}\n\nbody > div {\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
