import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Employee, EmployeeList } from '@/features/employees';
import { FieldList } from '@/features/fields';
import { FunctionList } from '@/features/functions';
import { Home } from '@/features/home';
import { MandatorsList } from '@/features/mandators';
import { Plan, Plans } from '@/features/plans';
import { Rates } from '@/features/rates';
import { RolesList } from '@/features/roles-list';
import { SubcontractorsList } from '@/features/subcontractors';
import { Subcontractor } from '@/features/subcontractors/Subcontractor';

export default () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/employees/:id" element={<Employee />} />
      <Route path="/employees" element={<EmployeeList />} />
      <Route path="/plans/:id" element={<Plan />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/rates" element={<Rates />} />
      <Route path="/mandators" element={<MandatorsList />} />
      <Route path="/settings/rollen" element={<RolesList />} />
      <Route path="/settings/fields" element={<FieldList />} />
      <Route path="/settings/functions" element={<FunctionList />} />
      <Route path="/subcontractors/:id" element={<Subcontractor />} />
      <Route path="/subcontractors" element={<SubcontractorsList />} />
    </Routes>
  );
};
