import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { Flex, Grid, Image, View } from '@adobe/react-spectrum';

import goldbeckLogo from '@/assets/goldbeck_logo.svg';
import { AppTabs } from '@/features/app-tabs';
import { AppTabList } from '@/features/app-tabs';

import { ErrorBoundary } from '../UI';
import { Slot } from '../UI/slot';

type ShellProps = {
  children: {
    sidebar: React.ReactElement;
    content: React.ReactElement;
  };
};

export const Shell: React.FC<ShellProps> = ({ children }) => {
  const location = useLocation();

  return (
    <Grid
      areas={['header  tabs', 'sidebar content']}
      columns={['size-3000', '1fr']}
      rows={['size-600', 'auto']}
      height="100%"
    >
      <View backgroundColor="gray-50" gridArea="header" padding="size-100">
        <Flex width={'100%'} height={'100%'}>
          <Image src={goldbeckLogo} height={27} width={150} justifySelf="flex-start" alignSelf="center" />
        </Flex>
      </View>

      <View gridArea="sidebar" backgroundColor="gray-50">
        {children.sidebar}
      </View>
      <View gridArea="tabs" backgroundColor="gray-50" zIndex={100}>
        <AppTabs>
          <AppTabList height="size-600" />
        </AppTabs>
      </View>
      <View gridArea="content" backgroundColor="gray-100" overflow="hidden" padding="size-200">
        <ErrorBoundary key={location.pathname + location.search + location.hash}>
          <Slot name="content" isDefault>
            {children.content}
          </Slot>
        </ErrorBoundary>
      </View>
    </Grid>
  );
};
