import * as React from 'react';

import {
    DialogTrigger as SpectrumDialogTrigger,
    SpectrumDialogTriggerProps,
} from '@adobe/react-spectrum';

interface DialogTriggerProps extends SpectrumDialogTriggerProps {}

export const DialogTrigger: React.FC<DialogTriggerProps> = (props) => (
    <SpectrumDialogTrigger {...props} />
);
