import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import {
    Content,
    Dialog,
    DialogContainer,
    Flex,
    Header,
    Heading,
    Loading,
} from '@/components/UI';

import { useAuthGateMeQuery } from './AuthGate.g';

export const AuthGate: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { data: { me } = {}, loading } = useAuthGateMeQuery();

    useEffect(() => {
        if (!me && !loading) {
            setTimeout(() => {
                window.location.href = LOGIN_URL + window.location.pathname;
            }, 2500);
        }
    }, [me]);

    return (
        <>
            {!me && (
                <DialogContainer onDismiss={() => {}}>
                    <Dialog size="S">
                        <Heading alignSelf={'center'}>
                            Anmeldung erforderlich...
                        </Heading>
                        <Content>
                            <Flex
                                justifyContent="center"
                                alignItems={'center'}
                                height={'size-1000'}
                            >
                                <Loading isLoading />
                            </Flex>
                        </Content>
                    </Dialog>
                </DialogContainer>
            )}
            {!!me && children}
        </>
    );
};

export default AuthGate;
