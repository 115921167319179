import * as React from 'react';
import { LegacyRef, forwardRef } from 'react';

import { DOMRefValue } from '@react-types/shared';

import { Form, FormProps, FormRefValue, TextField } from '@/components/UI';

type MandatorData = {
    name: string;
    site: string;
};

type MandatorFormProps = Omit<FormProps<MandatorData>, 'children'> & {
    mandator?: {
        id: string;
        name: string;
        site: string;
    };
};

export const MandatorForm = forwardRef<FormRefValue, MandatorFormProps>(
    ({ mandator, ...props }, ref) => {
        return (
            <Form {...props} ref={ref}>
                <TextField
                    name="name"
                    label="Name"
                    defaultValue={mandator?.name}
                    isRequired
                />
                <TextField
                    name="site"
                    label="Standort"
                    defaultValue={mandator?.site}
                />
            </Form>
        );
    },
);
