import * as React from 'react';

import {
    Heading as SpectrumHeading,
    HeadingProps as SpectrumHeadingProps,
} from '@adobe/react-spectrum';

interface HeadingProps extends SpectrumHeadingProps {}

export const Heading: React.FC<HeadingProps> = (props) => (
    <SpectrumHeading {...props} />
);
