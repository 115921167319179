import React, { PropsWithChildren } from 'react';
import {
    FallbackProps,
    ErrorBoundary as ReactErrorBoundary,
} from 'react-error-boundary';

import {
    ActionButton,
    Content,
    Heading,
    IllustratedMessage,
} from '@adobe/react-spectrum';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import Button from '@spectrum-icons/workflow/Button';

import { useCloseTab } from '@/features/app-tabs';

interface ErrorFallbackProps extends FallbackProps {
    resetErrorBoundary: () => void;
}

interface ErrorBoundaryProps {}

const ErrorFallback: React.FC<PropsWithChildren<ErrorFallbackProps>> = ({
    error,
    resetErrorBoundary,
}) => {
    const closeTab = useCloseTab();

    return (
        <IllustratedMessage>
            <NotFound />
            <Heading>Ein Fehler ist aufgetreten</Heading>
            <Content>
                <p>{error.message}</p>
                <ActionButton onPress={() => closeTab()}>
                    schließen
                </ActionButton>
            </Content>
        </IllustratedMessage>
    );
};

export const ErrorBoundary: React.FC<PropsWithChildren<ErrorBoundaryProps>> = ({
    children,
}) => (
    <ReactErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
    </ReactErrorBoundary>
);
