import * as React from 'react';

import {
    Divider as SpectrumDivider,
    SpectrumDividerProps,
} from '@adobe/react-spectrum';

interface DividerProps extends SpectrumDividerProps {}

export const Divider: React.FC<DividerProps> = (props) => (
    <SpectrumDivider {...props} />
);
