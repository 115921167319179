import React, { useDeferredValue, useEffect, useRef, useState } from 'react';

import {
  Checkbox,
  ComboBox,
  Content,
  ContextualHelp,
  DateField,
  DatePicker,
  Heading,
  InlineAlert,
  Item,
  ItemProps,
  Key,
  NumberField,
  Picker,
  Switch,
  Text,
  TextField,
  TimeField,
} from '@adobe/react-spectrum';
import {
  CalendarDateTime,
  Time,
  ZonedDateTime,
  parseDateTime,
  parseTime,
  parseZonedDateTime,
} from '@internationalized/date';
import { update } from 'cypress/types/lodash';

import { useDebounce, useOverlay } from '@/hooks';

import { FieldPartsFragment, useFieldValuesQuery } from './Fields.g';

type FieldEditorProps = {
  isReadOnly?: boolean;
  fields: FieldPartsFragment[];
  fieldData?: { [fieldId: string]: FieldData };
  onChange: (field: FieldPartsFragment, data: FieldData) => void;
};

export function FieldEditor({ fields, onChange, isReadOnly, fieldData }: FieldEditorProps) {
  const handleChange = (field: FieldPartsFragment, data: FieldData) => {
    if (!data) return;
    onChange(field, data);
  };

  return (
    <>
      {fields.map((field) => (
        <Field
          key={field.id}
          isReadOnly={isReadOnly}
          data={fieldData && fieldData[field.id]}
          field={field}
          onChange={(data) => handleChange(field, data)}
        />
      ))}
    </>
  );
}

type FieldValue = string | boolean | number | undefined;

export type FieldData = {
  value: FieldValue;
};

type FieldProps = {
  field: FieldPartsFragment;
  data?: FieldData;
  isQuiet?: boolean;
  isReadOnly?: boolean;
  hideLabel?: boolean;
  onChange: (data: FieldData) => void;
};

export function Field({ field, data, onChange, isReadOnly, isQuiet, hideLabel }: FieldProps) {
  const [change, _, setChange] = useOverlay(data);

  const updateValue = (value: string | boolean | number | undefined) => {
    if (data?.value === value) return;
    setChange({ value });
  };

  const handleBlur = () => {
    onChange(change);
  };

  const valuesQuery = useFieldValuesQuery({
    variables: {
      id: field.id,
    },
    skip: !['SELECT'].includes(field.type),
  });

  const values: FieldData[] = valuesQuery.data?.field.values || [];

  const value = change?.value;

  const contextualHelp = field.helpText ? (
    <ContextualHelp variant="help">
      <Heading level={4}>{field.name}</Heading>
      <Content>
        <Text>{field.helpText}</Text>
      </Content>
    </ContextualHelp>
  ) : undefined;

  switch (field.type) {
    case 'TEXT':
      return (
        <TextField
          isReadOnly={isReadOnly}
          isQuiet={isQuiet}
          contextualHelp={contextualHelp}
          description={field.description}
          width={'100%'}
          value={value?.toString()}
          onChange={(value) => updateValue(value)}
          label={!hideLabel && field.name}
          autoComplete={field.fieldName}
          onBlur={handleBlur}
        />
      );
    case 'DATE':
      return (
        <DatePicker
          isQuiet={isQuiet}
          contextualHelp={contextualHelp}
          isReadOnly={isReadOnly}
          description={field.description}
          granularity="day"
          width={'100%'}
          value={tryParseZonedDateTime(value?.toString())}
          onChange={(value) => updateValue(value.toString())}
          label={!hideLabel && field.name}
          onBlur={handleBlur}
        />
      );
    case 'DATETIME':
      return (
        <DatePicker
          isQuiet={isQuiet}
          contextualHelp={contextualHelp}
          isReadOnly={isReadOnly}
          description={field.description}
          label={!hideLabel && field.name}
          value={tryParseZonedDateTime(value?.toString())}
          onChange={(value) => updateValue(value.toString())}
          granularity="minute"
          width={'100%'}
          onBlur={handleBlur}
        />
      );

    case 'TIME':
      return (
        <TimeField
          isQuiet={isQuiet}
          contextualHelp={contextualHelp}
          isReadOnly={isReadOnly}
          description={field.description}
          label={!hideLabel && field.name}
          value={tryParseTime(value?.toString())}
          onChange={(value) => {
            return updateValue(value.toString());
          }}
          onBlur={handleBlur}
        />
      );

    case 'INTEGER':
      return (
        <NumberField
          isQuiet={isQuiet}
          contextualHelp={contextualHelp}
          isReadOnly={isReadOnly}
          width={'100%'}
          description={field.description}
          label={!hideLabel && field.name}
          value={parseInt(value?.toString())}
          onChange={(value) => {
            return updateValue(value);
          }}
          onBlur={handleBlur}
        />
      );

    case 'DECIMAL':
      return (
        <NumberField
          isQuiet={isQuiet}
          contextualHelp={contextualHelp}
          isReadOnly={isReadOnly}
          width={'100%'}
          description={field.description}
          label={!hideLabel && field.name}
          value={parseFloat(value?.toString())}
          onChange={(value) => {
            return updateValue(value);
          }}
          onBlur={handleBlur}
        />
      );

    case 'BOOL':
      const content = hideLabel ? undefined : (
        <>
          {field.name}
          {contextualHelp}
        </>
      );

      return (
        <Switch
          justifySelf={hideLabel ? 'center' : 'start'}
          isEmphasized
          isSelected={!!value}
          isReadOnly={isReadOnly}
          onChange={(value) => {
            updateValue(value);
          }}
          aria-label={field.name}
          onBlur={handleBlur}
        >
          {content}
        </Switch>
      );
    case 'SELECT':
      return (
        <FieldComboBox
          isQuiet={isQuiet}
          field={field}
          contextualHelp={contextualHelp}
          isReadOnly={isReadOnly}
          hideLabel={hideLabel}
          data={change}
          values={values}
          updateValue={updateValue}
          onBlur={handleBlur}
        />
      );
  }

  return (
    <InlineAlert variant="negative">
      <Heading>Fehlende Implementierung</Heading>
      <Content>Der Feldtyp {field.type} ist noch nicht vollständig implementiert.</Content>
    </InlineAlert>
  );
}

type FieldComponentProps = {
  field: FieldPartsFragment;
  contextualHelp: React.ReactNode;
  isReadOnly: boolean;
  isQuiet: boolean;
  hideLabel: boolean;
  data: FieldData;
  values: FieldData[];
  onBlur: () => void;
  updateValue: (value: string | boolean | number | undefined, immediate?: boolean) => void;
};

function FieldComboBox({
  field,
  data,
  contextualHelp,
  isQuiet,
  isReadOnly,
  hideLabel,
  values,
  onBlur,
  updateValue,
}: FieldComponentProps) {
  const items = [
    ...((field.settings.items as { value: string; label: string }[]) || []),
    ...values
      .filter((item) => !!item.value && item.value?.toString().length > 0)
      .map((item) => ({ value: item.value, label: item.value })),
  ];

  const uniqueItems = items.reduce((acc, currentItem) => {
    if (acc.findIndex((item) => item.value == currentItem.value) >= 0) {
      return acc;
    }

    acc.push(currentItem);

    return acc;
  }, []);

  const allowsCustomValue = !!field.settings.allowsCustomValue;

  const itemElements = (item: { value: string; label: string }) => <Item key={item.value}>{item.label}</Item>;

  return (
    <ComboBox
      contextualHelp={contextualHelp}
      isReadOnly={isReadOnly}
      description={field.description}
      width={'100%'}
      isQuiet={isQuiet}
      label={!hideLabel && field.name}
      items={uniqueItems}
      onBlur={onBlur}
      allowsCustomValue={allowsCustomValue}
      selectedKey={data?.value?.toString()}
      inputValue={data?.value?.toString()}
      onSelectionChange={updateValue}
      onInputChange={updateValue}
    >
      {itemElements}
    </ComboBox>
  );
}

function tryParseTime(value?: string): Time {
  if (!value) return undefined;

  try {
    return parseTime(value);
  } catch (error) {
    console.log(error);
  }

  return undefined;
}

function tryParseZonedDateTime(value?: string): CalendarDateTime | undefined {
  if (!value) return undefined;

  try {
    return parseDateTime(value);
  } catch (error) {
    console.log(error);
  }

  return undefined;
}
