import * as React from 'react';
import { BrowserRouter, useNavigate, useHref } from 'react-router-dom';

import {
  Provider as SpectrumProvider,
  defaultTheme,
} from '@adobe/react-spectrum';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
  concat,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import { Provider as ReduxProvider } from 'react-redux'
import { MandatorContext, useMandatorState } from './contexts/MandatorContext';

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

import store from "@/store"
import { getMandator } from './features/mandator-gate';

import theme from "./theme.css"

loadDevMessages();
loadErrorMessages();

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }, callback) => {
  if (networkError?.statusCode === 401) {
    console.error(`[Network error]: ${networkError}`);
    window.location.href = LOGIN_URL + window.location.pathname;
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }

});

const urls = (() => {
  const wsProtocol = window.location.protocol == 'https:' ? 'wss:' : 'ws:';
  const url = `//${window.location.hostname}:${window.location.port}`;
  return {
    ws: `${wsProtocol}${url}/graphql/`,
    http: `${window.location.protocol}${url}/graphql/`,
  };
})();
const httpLink = from([
  errorLink,
  new HttpLink({ uri: urls.http, credentials: 'same-origin' }),
]);

const wsLink = new GraphQLWsLink(
  createClient({
    url: urls.ws,
    credentials: 'same-origin',
  }),
);

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-Mandator-Id': getMandator(),
    },
  }));

  return forward(operation);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: concat(authMiddleware, splitLink),
  cache: new InMemoryCache({
    typePolicies: {
      Booking: {
        keyFields: ['slot', 'employee', ['id']],
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "cache-and-network"
    }
  }
});

const AppSpectrumProvider = ({ children }) => {
  const themeOverride = {
    ...defaultTheme,
  }

  const navigate = useNavigate();
  return <SpectrumProvider theme={themeOverride} router={{ navigate, useHref }} colorScheme="light" height="100%" scale='medium'>
    {children}

  </SpectrumProvider>
}

const GlobalProvider = ({ children }) => {
  const mandatorState = useMandatorState(() => client.resetStore());

  return (
    <ReduxProvider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <AppSpectrumProvider>
            <MandatorContext.Provider value={mandatorState}>
              {children}
            </MandatorContext.Provider>
          </AppSpectrumProvider>
        </BrowserRouter>
      </ApolloProvider>
    </ReduxProvider>
  );
};

export default GlobalProvider;
