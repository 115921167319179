import * as React from 'react';
import { useRef } from 'react';

import { DOMRefValue } from '@react-types/shared';

import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    DialogContainer,
    Divider,
    Footer,
    Form,
    FormRefValue,
    Heading,
    Loading,
    ProgressCircle,
} from '@/components/UI';

import { MandatorForm } from './MandatorForm';
import {
    useEditMandatorDialogQuery,
    useUpdateMandatorMutation,
} from './MandatorList.g';

interface EditMandatorProps {
    id: string;
    close: () => void;
}

export const EditMandatorDialog: React.FC<EditMandatorProps> = ({
    id,
    close,
}) => {
    const ref = useRef<FormRefValue>();

    const { data: { mandator } = {}, loading } = useEditMandatorDialogQuery({
        variables: { id },
    });

    const [updateMandator] = useUpdateMandatorMutation({
        onCompleted: () => close(),
    });

    return (
        <Dialog>
            <Heading>Mandant bearbeiten</Heading>
            <Divider />
            <Content>
                <Loading isLoading={loading}>
                    {() => (
                        <MandatorForm
                            ref={ref}
                            mandator={mandator}
                            onSubmit={(e, data) => {
                                return updateMandator({
                                    variables: {
                                        input: {
                                            id,
                                            ...data,
                                        },
                                    },
                                });
                            }}
                        />
                    )}
                </Loading>
            </Content>
            <ButtonGroup>
                <Button variant="secondary" onPress={close}>
                    Abbrechen
                </Button>
                <Button
                    variant="accent"
                    onPress={() => ref.current.requestSubmit()}
                >
                    Speichern
                </Button>
            </ButtonGroup>
        </Dialog>
    );
};
