import * as React from 'react';

import {
    Content as SpectrumContent,
    ContentProps as SpectrumContentProps,
} from '@adobe/react-spectrum';

interface ContentProps extends SpectrumContentProps {}

export const Content: React.FC<ContentProps> = (props) => (
    <SpectrumContent {...props} />
);
