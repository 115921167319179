import React from 'react';

import {
    IllustratedMessage as IllustratedMessageSpectrum,
    SpectrumIllustratedMessageProps,
} from '@adobe/react-spectrum';

interface IllustratedMessage extends SpectrumIllustratedMessageProps {}

export const IllustratedMessage: React.FC<IllustratedMessage> = (props) => (
    <IllustratedMessageSpectrum {...props} />
);
