import * as Types from '../../gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MandatorListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MandatorListQuery = { __typename?: 'RootQuery', mandators: { __typename?: 'MandatorQueryResult', items: Array<{ __typename?: 'Mandator', id: string, name: string, site: string }> } };

export type EditMandatorDialogQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type EditMandatorDialogQuery = { __typename?: 'RootQuery', mandator: { __typename?: 'Mandator', id: string, name: string, site: string } };

export type UpdateMandatorMutationVariables = Types.Exact<{
  input: Types.UpdateMandatorInput;
}>;


export type UpdateMandatorMutation = { __typename?: 'RootMutation', updateMandator: { __typename?: 'Mandator', id: string, name: string, site: string } | { __typename?: 'OperationInfo' } };

export type CreateMandatorMutationVariables = Types.Exact<{
  input: Types.CreateMandatorInput;
}>;


export type CreateMandatorMutation = { __typename?: 'RootMutation', createMandator: { __typename?: 'Mandator', id: string, name: string, site: string } | { __typename?: 'OperationInfo' } };


export const MandatorListDocument = gql`
    query MandatorList {
  mandators {
    items {
      id
      name
      site
    }
  }
}
    `;

/**
 * __useMandatorListQuery__
 *
 * To run a query within a React component, call `useMandatorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMandatorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMandatorListQuery({
 *   variables: {
 *   },
 * });
 */
export function useMandatorListQuery(baseOptions?: Apollo.QueryHookOptions<MandatorListQuery, MandatorListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MandatorListQuery, MandatorListQueryVariables>(MandatorListDocument, options);
      }
export function useMandatorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MandatorListQuery, MandatorListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MandatorListQuery, MandatorListQueryVariables>(MandatorListDocument, options);
        }
export type MandatorListQueryHookResult = ReturnType<typeof useMandatorListQuery>;
export type MandatorListLazyQueryHookResult = ReturnType<typeof useMandatorListLazyQuery>;
export type MandatorListQueryResult = Apollo.QueryResult<MandatorListQuery, MandatorListQueryVariables>;
export const EditMandatorDialogDocument = gql`
    query EditMandatorDialog($id: ID!) {
  mandator(id: $id) {
    id
    name
    site
  }
}
    `;

/**
 * __useEditMandatorDialogQuery__
 *
 * To run a query within a React component, call `useEditMandatorDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMandatorDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMandatorDialogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditMandatorDialogQuery(baseOptions: Apollo.QueryHookOptions<EditMandatorDialogQuery, EditMandatorDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMandatorDialogQuery, EditMandatorDialogQueryVariables>(EditMandatorDialogDocument, options);
      }
export function useEditMandatorDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMandatorDialogQuery, EditMandatorDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMandatorDialogQuery, EditMandatorDialogQueryVariables>(EditMandatorDialogDocument, options);
        }
export type EditMandatorDialogQueryHookResult = ReturnType<typeof useEditMandatorDialogQuery>;
export type EditMandatorDialogLazyQueryHookResult = ReturnType<typeof useEditMandatorDialogLazyQuery>;
export type EditMandatorDialogQueryResult = Apollo.QueryResult<EditMandatorDialogQuery, EditMandatorDialogQueryVariables>;
export const UpdateMandatorDocument = gql`
    mutation UpdateMandator($input: UpdateMandatorInput!) {
  updateMandator(input: $input) {
    ... on Mandator {
      id
      name
      site
    }
  }
}
    `;
export type UpdateMandatorMutationFn = Apollo.MutationFunction<UpdateMandatorMutation, UpdateMandatorMutationVariables>;

/**
 * __useUpdateMandatorMutation__
 *
 * To run a mutation, you first call `useUpdateMandatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMandatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMandatorMutation, { data, loading, error }] = useUpdateMandatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMandatorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMandatorMutation, UpdateMandatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMandatorMutation, UpdateMandatorMutationVariables>(UpdateMandatorDocument, options);
      }
export type UpdateMandatorMutationHookResult = ReturnType<typeof useUpdateMandatorMutation>;
export type UpdateMandatorMutationResult = Apollo.MutationResult<UpdateMandatorMutation>;
export type UpdateMandatorMutationOptions = Apollo.BaseMutationOptions<UpdateMandatorMutation, UpdateMandatorMutationVariables>;
export const CreateMandatorDocument = gql`
    mutation CreateMandator($input: CreateMandatorInput!) {
  createMandator(input: $input) {
    ... on Mandator {
      id
      name
      site
    }
  }
}
    `;
export type CreateMandatorMutationFn = Apollo.MutationFunction<CreateMandatorMutation, CreateMandatorMutationVariables>;

/**
 * __useCreateMandatorMutation__
 *
 * To run a mutation, you first call `useCreateMandatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMandatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMandatorMutation, { data, loading, error }] = useCreateMandatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMandatorMutation(baseOptions?: Apollo.MutationHookOptions<CreateMandatorMutation, CreateMandatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMandatorMutation, CreateMandatorMutationVariables>(CreateMandatorDocument, options);
      }
export type CreateMandatorMutationHookResult = ReturnType<typeof useCreateMandatorMutation>;
export type CreateMandatorMutationResult = Apollo.MutationResult<CreateMandatorMutation>;
export type CreateMandatorMutationOptions = Apollo.BaseMutationOptions<CreateMandatorMutation, CreateMandatorMutationVariables>;