import React from 'react';

import { ComboBox, Item, SpectrumComboBoxProps } from '@adobe/react-spectrum';

import { EmployeeSubcontractorsQueryResult, useEmployeeSubcontractorsQuery } from './Employees.g';

type Props<T> = Omit<SpectrumComboBoxProps<T>, 'children'>;

export function SubcontractorComboBox(props: Props<EmployeeSubcontractorsQueryResult['data']['subcontractors'][0]>) {
  const query = useEmployeeSubcontractorsQuery();

  return (
    <ComboBox
      label="Dienstleister"
      loadingState={query.loading ? 'loading' : 'idle'}
      items={query.data?.subcontractors || []}
      formValue="key"
      {...props}
    >
      {(item) => <Item key={item.id}>{item.name}</Item>}
    </ComboBox>
  );
}
