// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.components-UI-contextmenu-ContextMenu__ContextMenu--iogq5 {
  background: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/contextmenu/ContextMenu.css"],"names":[],"mappings":";AACA;EACE,eAAe;AACjB","sourcesContent":["\n.ContextMenu {\n  background: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContextMenu": `components-UI-contextmenu-ContextMenu__ContextMenu--iogq5`
};
export default ___CSS_LOADER_EXPORT___;
