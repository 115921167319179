import * as Types from '../../gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScopesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ScopesQuery = { __typename?: 'RootQuery', scopes: Array<{ __typename?: 'Scope', name: string, title: string, description: string, parent?: string | null }> };

export type RolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'RootQuery', roles: Array<{ __typename?: 'UserRole', id: string, title: string, scopes: Array<string>, isSystem: boolean }> };

export type UpdateOrInsertRoleMutationVariables = Types.Exact<{
  input: Types.UpdateOrInsertRoleInput;
}>;


export type UpdateOrInsertRoleMutation = { __typename?: 'RootMutation', updateOrInsertRole: { __typename?: 'UserRole', id: string, title: string, scopes: Array<string>, isSystem: boolean } };

export type DeleteRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteRoleMutation = { __typename?: 'RootMutation', deleteRole: boolean };


export const ScopesDocument = gql`
    query Scopes {
  scopes {
    name
    title
    description
    parent
  }
}
    `;

/**
 * __useScopesQuery__
 *
 * To run a query within a React component, call `useScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScopesQuery({
 *   variables: {
 *   },
 * });
 */
export function useScopesQuery(baseOptions?: Apollo.QueryHookOptions<ScopesQuery, ScopesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScopesQuery, ScopesQueryVariables>(ScopesDocument, options);
      }
export function useScopesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScopesQuery, ScopesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScopesQuery, ScopesQueryVariables>(ScopesDocument, options);
        }
export type ScopesQueryHookResult = ReturnType<typeof useScopesQuery>;
export type ScopesLazyQueryHookResult = ReturnType<typeof useScopesLazyQuery>;
export type ScopesQueryResult = Apollo.QueryResult<ScopesQuery, ScopesQueryVariables>;
export const RolesDocument = gql`
    query Roles {
  roles {
    id
    title
    scopes
    isSystem
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const UpdateOrInsertRoleDocument = gql`
    mutation UpdateOrInsertRole($input: UpdateOrInsertRoleInput!) {
  updateOrInsertRole(input: $input) {
    id
    title
    scopes
    isSystem
  }
}
    `;
export type UpdateOrInsertRoleMutationFn = Apollo.MutationFunction<UpdateOrInsertRoleMutation, UpdateOrInsertRoleMutationVariables>;

/**
 * __useUpdateOrInsertRoleMutation__
 *
 * To run a mutation, you first call `useUpdateOrInsertRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrInsertRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrInsertRoleMutation, { data, loading, error }] = useUpdateOrInsertRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrInsertRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrInsertRoleMutation, UpdateOrInsertRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrInsertRoleMutation, UpdateOrInsertRoleMutationVariables>(UpdateOrInsertRoleDocument, options);
      }
export type UpdateOrInsertRoleMutationHookResult = ReturnType<typeof useUpdateOrInsertRoleMutation>;
export type UpdateOrInsertRoleMutationResult = Apollo.MutationResult<UpdateOrInsertRoleMutation>;
export type UpdateOrInsertRoleMutationOptions = Apollo.BaseMutationOptions<UpdateOrInsertRoleMutation, UpdateOrInsertRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($id: ID!) {
  deleteRole(id: $id)
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, options);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;