import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '@/store';

import { TabIcon } from './TabIcons';
import { tabClosed, titleChanged } from './slice';

export const useTitle = (title: string, icon?: TabIcon) => {
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = title;
    });

    useEffect(() => {
        dispatch(titleChanged({ title, icon: icon || 'page' }));
    }, [title, icon]);
};

export const useCloseTab = () => {
    const appTabs = useSelector((state: RootState) => state.appTabs);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return () => {
        const tab = appTabs.tabs.filter(
            (t) => t.pathname == appTabs.activePath,
        )[0];
        if (!tab) return;
        const nextActivePath =
            appTabs.tabs[Math.max(0, appTabs.tabs.indexOf(tab) - 1)].pathname;
        navigate(nextActivePath);
        dispatch(tabClosed(appTabs.activePath));
    };
};
