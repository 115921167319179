import React, { Key, useState } from 'react';

import Edit from '@spectrum-icons/workflow/Edit';

import {
    ActionButton,
    Button,
    Cell,
    Column,
    DialogContainer,
    DialogTrigger,
    PageContent,
    PageHeader,
    Row,
    TableBody,
    TableHeader,
    TableView,
} from '@/components/UI';
import useHasPermission from '@/hooks/useHasPermissions';

import { CreateMandatorDialog } from './CreateMandatorDialog';
import { EditMandatorDialog } from './EditMandatorDialog';
import { useMandatorListQuery } from './MandatorList.g';

const ROLE_ID = 2;

export const MandatorsList: React.FC = () => {
    const [editKey, setEditKey] = useState<string>();
    const { data: { mandators: { items } = { items: [] } } = {} } =
        useMandatorListQuery();

    // PERMISSIONS
    const canCreateMandator = useHasPermission(ROLE_ID, 'mandator/create');
    const canEditMandator = useHasPermission(ROLE_ID, 'mandator/edit');

    return (
        <PageContent title={'Mandanten'}>
            <PageHeader>
                {canCreateMandator && (
                    <DialogTrigger>
                        <Button variant="accent">Mandant anlegen</Button>
                        {(close) => <CreateMandatorDialog close={close} />}
                    </DialogTrigger>
                )}
            </PageHeader>
            <TableView onAction={(key) => setEditKey(key.toString())}>
                <TableHeader>
                    <Column width={200}>Name</Column>
                    <Column>Standort</Column>
                </TableHeader>
                <TableBody items={items}>
                    {(item) => (
                        <Row key={item.id}>
                            <Cell>{item.name}</Cell>
                            <Cell>{item.site}</Cell>
                        </Row>
                    )}
                </TableBody>
            </TableView>

            <DialogTrigger isOpen={!!editKey}>
                <ActionButton isHidden>Bearbeiten</ActionButton>
                <EditMandatorDialog
                    id={editKey}
                    close={() => setEditKey(null)}
                />
            </DialogTrigger>
        </PageContent>
    );
};
