import * as Types from '../../gql';

import { gql } from '@apollo/client';
export type OperationInfoPartsFragment = { __typename?: 'OperationInfo', messages: Array<{ __typename?: 'OperationMessage', kind: Types.OperationMessageKind, message: string, field?: string | null, code?: string | null }> };

export const OperationInfoPartsFragmentDoc = gql`
    fragment OperationInfoParts on OperationInfo {
  messages {
    kind
    message
    field
    code
  }
}
    `;