import React, { useMemo, useState } from 'react';

import { over } from 'cypress/types/lodash';

export const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
  const callbackRef = React.useRef(callback);

  React.useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  let timer: number;

  const naiveDebounce = (func: (...args: any[]) => void, delayMs: number, ...args: any[]) => {
    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      func(...args);
    }, delayMs);
  };

  return React.useMemo(
    () =>
      (...args: any) =>
        naiveDebounce(callbackRef.current, delay, ...args),
    [delay]
  );
};

export function useOverlay<T>(value: T): [T, Partial<T>, React.Dispatch<React.SetStateAction<Partial<T>>>] {
  const [overlay, setOverlay] = useState<Partial<T>>();

  const result = useMemo(() => {
    if (value === undefined) return overlay;
    if (overlay === undefined) return value;

    return deepMerge(value, overlay);
  }, [overlay, value]);

  return [result, overlay, setOverlay];
}

function deepMerge(target: any, source: any) {
  if (!(target instanceof Object) || !(source instanceof Object)) return source;

  const copy = { ...target };

  Object.keys(source).forEach((key) => {
    copy[key] = deepMerge(copy[key], source[key]);
  });
  return copy;
}
