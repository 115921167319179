import React, { useState } from 'react';

export const MandatorContext = React.createContext([
    null,
    () => console.error('Mandator provider missing.'),
]);

export const useMandatorState = (onMandatorChange) => {
    const [mandatorId, setMandatorId] = useState(
        window.localStorage.getItem('mandator-id') || null,
    );

    const changeMandatorId = (newMandatorId) => {
        console.debug('Change mandator', newMandatorId);
        if (newMandatorId) {
            window.localStorage.setItem('mandator-id', newMandatorId);
        } else {
            window.localStorage.removeItem('mandator-id');
        }
        setMandatorId(() => newMandatorId || null);
        onMandatorChange();
    };

    return [mandatorId, changeMandatorId];
};
