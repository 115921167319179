import * as React from 'react';

import { Divider, Flex, Heading, Page, View } from '@/components/UI';

export const Home: React.FC<{}> = () => {
    return (
        <Page title="Startseite" icon="home">
            <Flex gap={'size-100'}>
                <View
                    flex="1 1 50%"
                    padding={'size-100'}
                    backgroundColor={'gray-50'}
                    borderRadius={'regular'}
                >
                    <Heading level={2} margin={0}>
                        Laufende Pläne
                    </Heading>
                    <Divider size="M" />
                </View>
                <View
                    flex="1 1 50%"
                    padding={'size-100'}
                    backgroundColor={'gray-50'}
                    borderRadius={'regular'}
                >
                    <Heading level={2} margin={0}>
                        Abwesende Mitarbeiter
                    </Heading>
                    <Divider size="M" />
                </View>
            </Flex>
        </Page>
    );
};
