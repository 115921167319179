import { DateFormatter } from "@internationalized/date"

const format = new Intl.DateTimeFormat();
const options = format.resolvedOptions()

export const browserLocale = options.locale
export const browserTimeZone = options.timeZone
export const browserFormatter = new DateFormatter(browserLocale, {
  timeZone: browserTimeZone
})
