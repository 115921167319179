import * as React from 'react';
import { useState } from 'react';

import {
  ActionBar,
  ActionBarContainer,
  Button,
  ButtonGroup,
  Cell,
  Column,
  Content,
  Divider,
  Flex,
  Header,
  Heading,
  IllustratedMessage,
  Item,
  Row,
  SearchField,
  Selection,
  StatusLight,
  Switch,
  TableBody,
  TableHeader,
  TableView,
  TagGroup,
  Text,
  View,
} from '@adobe/react-spectrum';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import Add from '@spectrum-icons/workflow/Add';
import Copy from '@spectrum-icons/workflow/Copy';
import Delete from '@spectrum-icons/workflow/Delete';
import Edit from '@spectrum-icons/workflow/Edit';

import { PageHeader } from '@/components/UI';
import { Page } from '@/components/UI/page';

function renderEmptyState() {
  return (
    <IllustratedMessage>
      <NotFound />
      <Heading>Keine Mitarbeiter gefunden</Heading>
    </IllustratedMessage>
  );
}

export const Rates: React.FC<React.PropsWithoutRef<{}>> = () => {
  const [keys, setKeys] = useState<Selection>(new Set([]));

  return (
    <Page title="Stundensätze" icon="money" height="100%">
      <PageHeader disabledActions={['create']} search={<SearchField isDisabled />}>
        <Item key="create">
          <Add />
          <Text>Stundensatz</Text>
        </Item>
      </PageHeader>

      <ActionBarContainer flex="1 1 50%" height={0} width="100%">
        <TableView
          selectionMode="multiple"
          selectionStyle="checkbox"
          selectedKeys={keys}
          onSelectionChange={(keys) => setKeys(keys)}
          renderEmptyState={renderEmptyState}
          overflowMode="wrap"
        >
          <TableHeader>
            <Column allowsSorting>Funktion</Column>
            <Column allowsSorting>Zielgruppe</Column>
            <Column allowsSorting>Mitarbeiter</Column>
            <Column width={200}>Gültig ab</Column>
            <Column width={200} align="end">
              Satz (€)
            </Column>
            <Column width={150}>Status</Column>
          </TableHeader>
          <TableBody>
            {[
              ...Array.from(Array(3)).map((_, i) => (
                <Row>
                  <Cell>Kassierer</Cell>
                  <Cell>
                    <TagGroup>
                      <Item>kfB</Item>
                      <Item>Subunternehmer</Item>
                      <Item>interne Mitarbeiter</Item>
                    </TagGroup>
                  </Cell>
                  <Cell>-</Cell>
                  <Cell>01.07.2024</Cell>
                  <Cell>15,00 €</Cell>
                  <Cell>
                    <StatusLight variant="positive">aktiv</StatusLight>
                  </Cell>
                </Row>
              )),
            ]}
          </TableBody>
        </TableView>
        <ActionBar
          isEmphasized
          selectedItemCount={keys === 'all' ? 'all' : keys.size}
          onAction={(key) => alert(`Performing ${key} action...`)}
          onClearSelection={() => setKeys(new Set())}
        >
          <Item key="copy">
            <Copy />
            <Text>Kopieren</Text>
          </Item>
          <Item key="delete">
            <Delete />
            <Text>Löschen</Text>
          </Item>
        </ActionBar>
      </ActionBarContainer>
    </Page>
  );
};
