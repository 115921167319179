import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Cell,
  Column,
  Key,
  Row,
  SearchField,
  Selection,
  TableBody,
  TableHeader,
  TableView,
} from '@adobe/react-spectrum';
import { useQuery } from '@apollo/client';
import { DateFormatter, parseDate } from '@internationalized/date';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import Add from '@spectrum-icons/workflow/Add';
import Copy from '@spectrum-icons/workflow/Copy';
import Delete from '@spectrum-icons/workflow/Delete';
import Edit from '@spectrum-icons/workflow/Edit';

import {
  ActionBarContainer,
  Button,
  ButtonGroup,
  Dialog,
  DialogTrigger,
  Flex,
  Header,
  Heading,
  IllustratedMessage,
  Item,
  PageHeader,
  SearchInput,
  StatusLight,
  Switch,
  Text,
} from '@/components/UI';
import { ActionBar, Page } from '@/components/UI';
import { browserFormatter, browserTimeZone } from '@/intl';

import { useCreatePlanMutation, usePlansQuery } from './Plans.g';

function renderEmptyState() {
  return (
    <IllustratedMessage>
      <NotFound />
      <Heading>Keine PLäne gefunden</Heading>
    </IllustratedMessage>
  );
}

export const Plans: React.FC<React.PropsWithoutRef<{}>> = () => {
  const navigate = useNavigate();
  const [keys, setKeys] = useState<Selection>(new Set([]));
  const [createPlan] = useCreatePlanMutation({
    variables: {
      input: {
        name: 'Neuer Plan',
        start: new Date().toISOString().substring(0, 10),
        end: new Date().toISOString().substring(0, 10),
        site: '',
      },
    },
    onCompleted: (data) => {
      if (data.createPlan.__typename == 'Plan') {
        navigate(`/plans/${data.createPlan.id}`);
      }
    },
  });
  const { data: { plans } = { plans: [] } } = usePlansQuery({
    fetchPolicy: 'cache-and-network',
  });

  const onAction = (action: Key) => {
    if (action == 'create') createPlan();
  };

  return (
    <Page title="Pläne" icon="list" height="100%">
      <PageHeader onAction={onAction} search={<SearchField />}>
        <Item key="create">
          <Add />
          <Text>Plan</Text>
        </Item>
      </PageHeader>
      <ActionBarContainer flex="1 1 50%" height={0} width="100%">
        <TableView
          selectionMode="multiple"
          selectionStyle="checkbox"
          selectedKeys={keys}
          onSelectionChange={(keys) => setKeys(keys)}
          renderEmptyState={renderEmptyState}
        >
          <TableHeader>
            <Column>Name</Column>
            <Column width={200}>Von</Column>
            <Column width={200}>Bis</Column>
            <Column width={200}>Kostenstelle</Column>
            <Column width={150}>Status</Column>
          </TableHeader>
          <TableBody items={plans}>
            {(plan) => (
              <Row key={plan.id} href={`/plans/${plan.id}`}>
                <Cell>{plan.name}</Cell>
                <Cell>{browserFormatter.format(parseDate(plan.start).toDate(browserTimeZone))}</Cell>
                <Cell>{browserFormatter.format(parseDate(plan.end).toDate(browserTimeZone))}</Cell>
                <Cell>-</Cell>
                <Cell>
                  <StatusLight variant="neutral">unbekannt</StatusLight>
                </Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
        <ActionBar
          isEmphasized
          selectedItemCount={keys === 'all' ? 'all' : keys.size}
          onAction={(key) => alert(`Performing ${key} action...`)}
          onClearSelection={() => setKeys(new Set())}
        >
          <Item key="copy">
            <Copy />
            <Text>Kopieren</Text>
          </Item>
          <Item key="delete">
            <Delete />
            <Text>Löschen</Text>
          </Item>
        </ActionBar>
      </ActionBarContainer>
    </Page>
  );
};
