// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-UI-custom-table-CustomTable__customTable--LCVEg {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
}

.components-UI-custom-table-CustomTable__columnHeader--wCAXI {
    background-color: #f0f0f0;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    border: 1px solid #ccc;
}

.components-UI-custom-table-CustomTable__cell--N_Opw {
    padding: 10px;
    border: 1px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/custom-table/CustomTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".customTable {\n    width: 100%;\n    border-collapse: collapse;\n    border: 1px solid #ccc;\n}\n\n.columnHeader {\n    background-color: #f0f0f0;\n    font-weight: bold;\n    text-align: left;\n    padding: 10px;\n    border: 1px solid #ccc;\n}\n\n.cell {\n    padding: 10px;\n    border: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customTable": `components-UI-custom-table-CustomTable__customTable--LCVEg`,
	"columnHeader": `components-UI-custom-table-CustomTable__columnHeader--wCAXI`,
	"cell": `components-UI-custom-table-CustomTable__cell--N_Opw`
};
export default ___CSS_LOADER_EXPORT___;
