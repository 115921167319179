import * as React from 'react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDialogContainer } from '@adobe/react-spectrum';
import { HelpTextProps } from '@react-types/shared';

import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Form,
  FormRef,
  FormRefValue,
  Heading,
  TextField,
} from '@/components/UI';

import { EmployeesDocument, useAddEmployeeMutation } from './Employees.g';
import { SubcontractorComboBox } from './SubcontractorComboBox';

type CreateEmployeeDialogProps = {};

type CreateEmployeeDialogData = {
  email: string;
  externalId: string;
  firstName: string;
  lastName: string;
  telephone: string;
  subcontractorId: string;
};

export const CreateEmployeeDialog: React.FC<CreateEmployeeDialogProps> = ({}) => {
  const ref = useRef<FormRefValue>();
  const dialog = useDialogContainer();
  const [addEmployee, addEmployeeStatus] = useAddEmployeeMutation();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>, data: CreateEmployeeDialogData) => {
    addEmployee({
      variables: {
        input: {
          ...data,
        },
      },
      refetchQueries: [EmployeesDocument],
      onCompleted: (data) => {
        if (data.addEmployee.__typename == 'Employee') {
          dialog.dismiss();
        }
      },
    });
  };

  const errorMessageProps: (field: string) => HelpTextProps = (field) => {
    if (addEmployeeStatus.data?.addEmployee.__typename == 'OperationInfo') {
      const errorMessage = addEmployeeStatus.data.addEmployee.messages
        .filter((m) => m.field === field)
        .map((m) => m.message)
        .join('\n');

      return {
        validationState: errorMessage && 'invalid',
        errorMessage,
      };
    }

    return {};
  };

  console.log({ addEmployeeStatus });

  return (
    <Dialog>
      <Heading>Mitarbeiter anlegen</Heading>
      <Divider />
      <Content>
        <Form ref={ref} onSubmit={onSubmit}>
          <TextField errorMessage name="firstName" label="Vorname" isRequired {...errorMessageProps('firstName')} />
          <TextField name="lastName" label="Nachname" isRequired {...errorMessageProps('lastName')} />
          <TextField name="email" label="E-Mail-Adresse" type="email" {...errorMessageProps('email')} />
          <TextField name="externalId" label="Mitarbeiternummer" {...errorMessageProps('externalId')} />
          <SubcontractorComboBox name="subcontractorId" />
        </Form>
      </Content>
      <ButtonGroup>
        <Button onPress={() => dialog.dismiss()} variant="secondary">
          Abbrechen
        </Button>
        <Button onPress={() => ref.current.requestSubmit()} variant="accent">
          Erstellen
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};
