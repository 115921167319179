import * as React from 'react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';

import IconAdd from '@spectrum-icons/workflow/Add';

import { Button, ButtonGroup, Content, Dialog, Divider, Footer, Form, FormRefValue, Heading } from '@/components/UI';

import { MandatorForm } from './MandatorForm';
import { MandatorListDocument, useCreateMandatorMutation } from './MandatorList.g';

type CreateMandatorDialog = {
  close: () => void;
};

export const CreateMandatorDialog: React.FC<CreateMandatorDialog> = ({ close }) => {
  const ref = useRef<FormRefValue>();
  const [createMandator] = useCreateMandatorMutation({
    onCompleted: () => close(),
    refetchQueries: [MandatorListDocument],
  });

  return (
    <Dialog>
      <Heading>Mandant anlegen</Heading>
      <Divider />
      <Content>
        <MandatorForm
          ref={ref}
          onSubmit={(e, data) =>
            createMandator({
              variables: { input: { ...data } },
            })
          }
        />
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={close}>
          Abbrechen
        </Button>
        <Button onPress={() => ref.current.requestSubmit()} variant="accent">
          Erstellen
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};
