import * as React from 'react';

import {
    Flex as SpectrumFlex,
    FlexProps as SpectrumFlexProps,
} from '@adobe/react-spectrum';

interface FlexProps extends SpectrumFlexProps {}

export const Flex: React.FC<FlexProps> = (props) => <SpectrumFlex {...props} />;
