import * as React from 'react';
import {
    FormEvent,
    Ref,
    createRef,
    forwardRef,
    useImperativeHandle,
    useRef,
} from 'react';

import { Form as SpectrumForm, SpectrumFormProps } from '@adobe/react-spectrum';
import { DOMRefValue } from '@react-types/shared';

export type FormRefValue = {
    requestSubmit: () => void;
};

export type FormRef = Ref<FormRefValue>;

export type FormProps<T> = Omit<SpectrumFormProps, 'ref' | 'onSubmit'> & {
    onSubmit?: (event: FormEvent<HTMLFormElement>, data: T) => void;
};

function FormComponent<T>(
    props: FormProps<T>,
    ref: React.ForwardedRef<FormRefValue>,
) {
    const formRef = useRef<DOMRefValue<HTMLFormElement>>();
    const { onSubmit, ...rest } = props;

    useImperativeHandle(ref, () => ({
        requestSubmit: () =>
            formRef.current.UNSAFE_getDOMNode().requestSubmit(),
    }));

    const onSubmitWithData = (e: FormEvent<HTMLFormElement>) => {
        if (!onSubmit) return;
        e.preventDefault();
        const data: { [key: string]: unknown } = {};
        new FormData(e.currentTarget).forEach((value, key) => {
            if (data[key] !== undefined) {
                if (Array.isArray(data[key])) {
                    (data[key] as Array<unknown>).push(value);
                } else {
                    data[key] = [value];
                }
            } else {
                data[key] = value;
            }
        }),
            onSubmit(e, data as T);
    };

    return (
        <SpectrumForm
            ref={formRef}
            onSubmit={onSubmitWithData}
            validationBehavior="native"
            necessityIndicator="label"
            {...rest}
        />
    );
}

export const Form = forwardRef(FormComponent);
