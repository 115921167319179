import React, { ReactElement, ReactNode, useContext } from 'react';

import { SpectrumSearchFieldProps } from '@adobe/react-spectrum';
import { Search } from '@drctnl/library/dist/atoms/Icons';
import { ItemElement, ItemRenderer } from '@react-types/shared';
import { Key } from '@react-types/shared';
import Close from '@spectrum-icons/workflow/Close';
import Home from '@spectrum-icons/workflow/Home';

import {
  ActionButton,
  ActionGroup,
  Breadcrumbs,
  Divider,
  Flex,
  Header,
  Heading,
  Item,
  ItemProps,
  TabList,
  Tabs,
  Text,
  Title,
  View,
} from '@/components/UI';
import { useCloseTab, useTitle } from '@/features/app-tabs';
import { TabIcon, TabIcons } from '@/features/app-tabs/TabIcons';

import { PageContext } from '../page/Page';

type Action<T> = ItemElement<T> | ItemElement<T>[] | ItemRenderer<T>;

interface PageHeaderProps {
  children?: Action<unknown>;
  disabledActions?: string[];
  onAction?: (key: Key) => void;
  secondary?: React.ReactNode;
  search?: React.ReactElement<SpectrumSearchFieldProps>;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ children, disabledActions, onAction, secondary, search }) => {
  const closeTab = useCloseTab();
  const pageContext = useContext(PageContext);

  const Icon = TabIcons[pageContext.icon];

  return (
    <View>
      <Header>
        <Flex justifyContent={'space-between'} gap={'size-100'} width={'100%'}>
          <Flex gap={'size-100'} alignItems={'center'} marginBottom={'size-250'}>
            <ActionButton isQuiet onPress={() => closeTab()}>
              <Close />
            </ActionButton>
            <Icon />
            <Heading level={2} margin={0}>
              {pageContext.title}
            </Heading>
          </Flex>
        </Flex>
        <Flex
          flex="1 1 20%"
          gap={'size-200'}
          marginBottom={'size-100'}
          width={'100%'}
          wrap={true}
          justifyContent={'start'}
          alignItems={'center'}
        >
          {search}
          {children && (
            <ActionGroup
              overflowMode="collapse"
              buttonLabelBehavior="collapse"
              disabledKeys={disabledActions}
              onAction={onAction}
            >
              {children}
            </ActionGroup>
          )}
          {secondary}
        </Flex>
        <Divider size="S" marginBottom={'size-100'} />
      </Header>
    </View>
  );
};
