import * as React from 'react';

type SlotProps = {
    name: string;
    isDefault?: boolean;
    children: React.ReactNode | { [key: string]: React.ReactNode };
};

export const Slot: React.FC<SlotProps> = ({
    name,
    isDefault,
    children,
    ...props
}) => {
    return (
        <>
            {React.Children.toArray(children as React.ReactNode).filter(
                (child) => {
                    if (React.isValidElement<{ slot: string }>(child)) {
                        return (
                            child.props.slot === name ||
                            (!child.props.slot && isDefault)
                        );
                    }

                    return isDefault;
                },
            )}
        </>
    );
};
