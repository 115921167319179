import React, { ReactElement } from 'react';
import { ColumnProps, Item, Key, Section, Selection } from 'react-stately';

import {
  Button,
  ButtonGroup,
  Checkbox,
  ComboBox,
  Content,
  ContextualHelp,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Form,
  Grid,
  Heading,
  IllustratedMessage,
  Switch,
  Text,
  TextArea,
  TextField,
  TimeField,
  Tooltip,
  TooltipTrigger,
  View,
  minmax,
  repeat,
  useFilter,
} from '@adobe/react-spectrum';
import { parseAbsolute, parseDateTime, parseZonedDateTime } from '@internationalized/date';
import { CellElement } from '@react-types/table';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import Info from '@spectrum-icons/workflow/Info';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import { debug } from 'webpack';

import { ActionMenu, Badge, Cell, Column, Row, TableBody, TableHeader, TableView } from '@/components/UI';
import { UpdatePositionInput } from '@/gql';
import { useDebounce, useOverlay } from '@/hooks';

import { useDialogs } from '../dialogs/hooks';
import { Field } from '../fields/FieldEditor';
import { FieldData } from '../fields/FieldFilter';
import { FieldPartsFragment, FieldValuesDocument } from '../fields/Fields.g';
import { EditHintDialog } from './EditHintDialog';
import { PlanQueryResult, useUpdatePositionMutation } from './Plans.g';

type PlanTableProps = {
  result: PlanQueryResult;
  selectedKeys: Selection;
  search?: string;
  onSelectionChange: (keys: Selection) => void;
};

function columnWidth(field: PlanQueryResult['data']['positionFields'][0]) {
  switch (field.type) {
    case 'BOOL':
      return '5ch';
    case 'TIME':
      return '13ch';
    default:
      return minmax('20ch', '35ch');
  }
}

export function PlanTable({ result, search, selectedKeys, onSelectionChange }: PlanTableProps) {
  let { contains } = useFilter({
    sensitivity: 'base',
  });

  const data = result.data;

  const columns = [
    '3ch',
    '4ch',
    ...(result.data?.positionFields || []).map(columnWidth),
    '13ch',
    '13ch',
    minmax('20ch', '1fr'),
  ];

  const positions =
    data?.plan.positions.filter((position) => {
      if (!search) return true;
      if (contains(position.note || '', search)) return true;
      if (position.fieldValues.some((fieldValue) => contains(fieldValue.data?.value?.toString() || '', search))) {
        return true;
      }
    }) || [];

  return (
    <View
      backgroundColor={'static-white'}
      overflow={'hidden auto'}
      position={'relative'}
      borderRadius={'regular'}
      borderColor={'gray-300'}
      borderWidth={'thin'}
    >
      <View
        paddingX={'size-150'}
        paddingY={'size-50'}
        backgroundColor={'static-white'}
        position={'sticky'}
        top={0}
        zIndex={100}
        borderBottomColor={'gray-300'}
        borderBottomWidth={'thin'}
      >
        <Grid columns={columns} gap={'size-75'} marginBottom={'size-75'}>
          {positionHeaderColumns(data?.positionFields || [], selectedKeys, onSelectionChange)}
        </Grid>
      </View>
      <View paddingX={'size-150'} paddingY={'size-100'} overflow={'auto auto'} flex="1 1 100%" minHeight={0}>
        <Grid flex="1 1 100%" minHeight={0} gap={'size-75'} columns={columns}>
          {positions.map((pos, index) => (
            <Position
              key={pos.id}
              index={index}
              position={pos}
              data={data}
              isSelected={selectedKeys == 'all' || selectedKeys.has(pos.id)}
              onSelectionChange={(isSelected) => {
                if (!onSelectionChange) return;
                const keys = new Set(selectedKeys);
                if (isSelected) {
                  keys.add(pos.id);
                } else {
                  keys.delete(pos.id);
                }

                onSelectionChange(keys);
              }}
            />
          ))}
        </Grid>
      </View>
    </View>
  );
}

type PositionData = PlanQueryResult['data']['plan']['positions'][0];

type PositionProps = {
  index: number;
  position: PositionData;
  data: PlanQueryResult['data'];
  isSelected?: boolean;
  onSelectionChange?: (isSelected: boolean) => void;
};

function Position({ index, position, data, isSelected, onSelectionChange }: PositionProps) {
  const fields = data.positionFields || [];
  const [overlay, changes, setOverlay] = useOverlay(position);

  const [updatePosition] = useUpdatePositionMutation({
    variables: {
      input: {
        positionId: position.id,
        ...changes,
      },
    },
    onCompleted(data, clientOptions) {
      setOverlay(undefined);
    },
  });

  const onFieldChange = (field: FieldPartsFragment, data: FieldData) => {
    updatePosition({
      variables: {
        input: {
          positionId: position.id,
          values: {
            [field.id]: data,
          },
        },
      },
      refetchQueries: [{ query: FieldValuesDocument, variables: { id: field.id } }],
    });
  };

  const onUpdatePosition = (values: Partial<Omit<UpdatePositionInput, 'positionId'>>) => {
    setOverlay(values);
  };

  return (
    <>
      <Checkbox
        justifySelf={'center'}
        isEmphasized
        isSelected={isSelected}
        onChange={(isSelected) => onSelectionChange && onSelectionChange(isSelected)}
      />
      <View>
        <DialogTrigger isDismissable type="popover">
          <TooltipTrigger placement="right bottom" isDisabled={!position.note?.length} delay={100}>
            <Button variant={!!position.note?.length ? (position.noteIsImportant ? 'accent' : 'primary') : 'secondary'}>
              <InfoOutline />
            </Button>
            <Tooltip showIcon>{position.note}</Tooltip>
          </TooltipTrigger>
          <EditHintDialog id={position.id} />
        </DialogTrigger>
      </View>
      {...fields.map((f) => (
        <Field
          key={f.id}
          data={position.fieldValues.find((fv) => fv.fieldId == f.id)?.data}
          hideLabel
          field={f}
          onChange={(data) => onFieldChange(f, data)}
        />
      ))}
      <TimeField
        width={'100%'}
        granularity="minute"
        value={parseDateTime(overlay.start)}
        onChange={(value) => onUpdatePosition({ start: value.toString() })}
        onBlur={() => updatePosition()}
      />
      <TimeField
        width={'100%'}
        granularity="minute"
        value={parseDateTime(overlay.end)}
        onChange={(value) => onUpdatePosition({ end: value.toString() })}
        onBlur={() => updatePosition()}
      />
      <ComboBox width={'100%'}>
        <Section title="Mitarbeiter">
          <Item>Test</Item>
        </Section>
        <Section title="Dienstleister">
          <Item>Test</Item>
        </Section>
      </ComboBox>
    </>
  );
}

type PositionField = PlanQueryResult['data']['positionFields'][0];

function positionHeaderColumns(
  fields: PositionField[],
  selectedKeys: Selection,
  onSelectionChange: (keys: Selection) => void
) {
  return [
    <Checkbox
      isEmphasized
      isSelected={selectedKeys == 'all'}
      isIndeterminate={selectedKeys && selectedKeys != 'all' && selectedKeys.size > 0}
      onChange={(isSelected) => {
        if (!onSelectionChange) return;
        if (isSelected) {
          onSelectionChange('all');
        } else {
          onSelectionChange(new Set());
        }
      }}
    />,
    <View />,
    ...fields.map((f) => (
      <Heading alignSelf={'center'} margin={0} level={4}>
        {f.name}
      </Heading>
    )),
    <Heading alignSelf={'center'} margin={0} level={4}>
      Beginn
    </Heading>,
    <Heading alignSelf={'center'} margin={0} level={4}>
      Ende
    </Heading>,
    <Heading alignSelf={'center'} margin={0} level={4}>
      Mitarbeiter
    </Heading>,
  ];
}

function renderEmptyState() {
  return (
    <IllustratedMessage>
      <NotFound />
      <Heading>Dieser Plan enhält noch keine Positionen</Heading>
    </IllustratedMessage>
  );
}
