import { Middleware, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/store';

import { TabIcon } from './TabIcons';

export type AppTab = {
  pathname: string;
  search: string;
  hash: string;
  title: string;
  icon: TabIcon;
};

export type AppTabsState = {
  activePath?: string;
  tabs: AppTab[];
};

export type PathChanged = AppTab;

export type TitleChanged = {
  title: string;
  icon?: TabIcon;
};

export const defaultInitialState: AppTabsState = {
  activePath: '/',
  tabs: [
    {
      pathname: '/',
      search: '',
      hash: '',
      title: 'Startseite',
      icon: 'home',
    },
  ],
};

export const appTabsMiddleware: Middleware<{}, RootState> = (store) => (next) => (action: PayloadAction<unknown>) => {
  const result = next(action);

  if (action.type?.startsWith('appTabs/')) {
    const appTabs = store.getState().appTabs;
    sessionStorage.setItem('appTabs', JSON.stringify(appTabs));
  }

  return result;
};

const slice = createSlice({
  name: 'appTabs',
  initialState: defaultInitialState,

  reducers: {
    activePathChanged: (state, action: PayloadAction<PathChanged>) => {
      const currentIndex = state.tabs.findIndex((tab) => tab.pathname == state.activePath);
      const nextIndex = state.tabs.findIndex((tab) => tab.pathname == action.payload.pathname);

      state.activePath = action.payload.pathname;

      if (nextIndex >= 0) {
        if (action.payload.search || action.payload.hash) {
          state.tabs.splice(nextIndex, 1, action.payload);
        }
      } else if (currentIndex >= 0) {
        state.tabs.splice(currentIndex + 1, 0, action.payload);
      } else {
        state.tabs.push(action.payload);
      }
    },
    titleChanged: (state, action: PayloadAction<TitleChanged>) => {
      const tab = state.tabs?.filter((tab) => tab.pathname == state.activePath)[0];
      if (!tab) return;
      tab.title = action.payload.title;
      tab.icon = action.payload.icon;
    },
    tabClosed: (state, action: PayloadAction<string>) => {
      const tab = state.tabs?.filter((tab) => tab.pathname == action.payload)[0];
      if (!tab) return;
      const tabIndex = state.tabs.indexOf(tab);
      state.tabs.splice(tabIndex, 1);
    },
  },
});

export const { activePathChanged, titleChanged, tabClosed } = slice.actions;
export default slice.reducer;
