import React, { ReactElement } from 'react';

import { Content, Heading, InlineAlert, SpectrumFormProps } from '@adobe/react-spectrum';
import { MutationResult, QueryResult } from '@apollo/client';
import { ValidationError, ValidationErrors } from '@react-types/shared';

import { OperationInfo } from '@/gql';

type Props = {
  results?: MutationResult<unknown>[];
  children: ReactElement<SpectrumFormProps>;
};

function isOperationInfo(data: any): data is OperationInfo {
  return data?.hasOwnProperty('__typename') && data['__typename'] == 'OperationInfo';
}

export function ErrorDialog({ children, results = [] }: Props) {
  const errors: ValidationErrors = {};

  results.forEach((result) => {
    if (isOperationInfo(result.data)) {
      copyMessages(result.data, errors);
    } else if (typeof result.data === 'object') {
      const data = result.data as { [key: string]: any };
      const keys = Object.keys(result.data);
      keys.map((key) => {
        if (isOperationInfo(data[key])) {
          copyMessages(data[key], errors);
        }
      });
    }
  });

  const globalErrors: string[] = errors['__global'] as string[];

  return (
    <>
      {globalErrors?.length > 0 && (
        <InlineAlert width={'100%'} variant="negative">
          <Heading>Fehler</Heading>
          <Content>
            {globalErrors.map((error) => (
              <p>{error}</p>
            ))}
          </Content>
        </InlineAlert>
      )}
      {React.cloneElement(children, { validationErrors: errors })}
    </>
  );
}
function copyMessages(data: OperationInfo, errors: ValidationErrors) {
  data.messages.forEach((msg) => {
    const field = msg.field || '__global';
    let fieldErrors: ValidationError = errors[field];
    if (!fieldErrors) {
      errors[field] = fieldErrors = [];
    }

    if (Array.isArray(fieldErrors)) {
      fieldErrors.push(msg.message);
    }
  });
}
