import { useState } from 'react';

import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Form,
  Heading,
  TextArea,
  TextField,
  useDialogContainer,
} from '@adobe/react-spectrum';
import { ValidationErrors } from '@react-types/shared';

import { CreateSubcontractorInput } from '@/gql';

import { SubcontractorsDocument, useCreateSubcontractorMutation } from './Subcontractor.g';

type Props = {};

type Subcontractor = CreateSubcontractorInput;

export const CreateSubcontractorDialog = (props: Props) => {
  const container = useDialogContainer();

  const [form, setForm] = useState<Subcontractor>({
    name: '',
    address: '',
  });

  const [createSubcontractor] = useCreateSubcontractorMutation({
    variables: {
      input: form,
    },
    onCompleted: () => container.dismiss(),
    refetchQueries: [SubcontractorsDocument],
  });

  const [errors, setErrors] = useState<ValidationErrors>({});

  const onChange = (change: Partial<Subcontractor>) => {
    setForm((form) => ({ ...form, ...change }));
  };

  const save = () => {
    const errors: ValidationErrors = {};
    if (!form.name) {
      errors['name'] = 'Der Name ist ein Pflichtfeld';
    }

    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    createSubcontractor();
  };

  console.log({ errors });

  return (
    <Dialog>
      <Heading>Subunternehmer erstellen</Heading>
      <Divider />
      <Content>
        <Form validationErrors={errors}>
          <TextField label="Name" name="name" value={form.name} isRequired onChange={(name) => onChange({ name })} />
          <TextArea label="Adresse" name="address" value={form.address} onChange={(address) => onChange({ address })} />
        </Form>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={container.dismiss}>
          abbrechen
        </Button>
        <Button variant="accent" onPress={() => save()}>
          Subunternehmer erstellen
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};
