import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActionButton, DialogContainer, Key, Selection } from '@adobe/react-spectrum';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import Add from '@spectrum-icons/workflow/Add';
import Copy from '@spectrum-icons/workflow/Copy';
import Delete from '@spectrum-icons/workflow/Delete';
import Edit from '@spectrum-icons/workflow/Edit';

import {
  ActionBar,
  ActionBarContainer,
  Button,
  ButtonGroup,
  Cell,
  Column,
  Dialog,
  DialogTrigger,
  Flex,
  Header,
  Heading,
  IllustratedMessage,
  Item,
  PageHeader,
  Row,
  SearchInput,
  StatusLight,
  Switch,
  TableBody,
  TableHeader,
  TableView,
  Text,
} from '@/components/UI';
import { Page } from '@/components/UI/page';

import { ErrorDialog } from '../errors/ErrorDialog';
import { CreateFieldDialog, EditFieldDialog } from './FieldDialog';
import { FieldsDocument, useDeleteFieldsMutation, useFieldsQuery } from './Fields.g';
import { FieldTypes, FieldTypesMap, FieldUsages, FieldUsagesMap } from './constants';

function renderEmptyState() {
  return (
    <IllustratedMessage>
      <NotFound />
      <Heading>Keine Mitarbeiter gefunden</Heading>
    </IllustratedMessage>
  );
}

type EditDialog = {
  name: 'edit';
  fieldId: string;
};

type Dialog = undefined | 'create' | EditDialog;

function isEditDialog(value: Dialog): value is EditDialog {
  return typeof value === 'object' && value['name'] == 'edit';
}

export const FieldList: React.FC<React.PropsWithoutRef<{}>> = () => {
  const { data: { fields } = { fields: [] } } = useFieldsQuery();

  const [keys, setKeys] = useState<Selection>(new Set([]));
  const [dialog, setDialog] = useState<Dialog>();

  const [deleteFields] = useDeleteFieldsMutation({
    variables: {
      ids: keys == 'all' ? fields.map((f) => f.id) : Array.from(keys).map((k) => k.toString()),
    },
    refetchQueries: [FieldsDocument],
    onCompleted: () => setKeys(new Set([])),
  });

  const onAction = (key: string) => {
    switch (key) {
      case 'create':
        setDialog('create');
        break;
      case 'delete':
        deleteFields();
        break;
    }
  };

  return (
    <Page title="Felder" icon="list" height="100%">
      <Flex direction="column" height="100%" gap={'size-200'}>
        <PageHeader onAction={onAction}>
          <Item key={'create'}>
            <Add />
            <Text>erstellen</Text>
          </Item>
        </PageHeader>
        <DialogContainer onDismiss={() => setDialog(undefined)}>
          {isEditDialog(dialog) && <EditFieldDialog fieldId={dialog.fieldId} />}
          {dialog == 'create' && <CreateFieldDialog onCreate={(id) => setDialog(undefined)} />}
        </DialogContainer>

        <ActionBarContainer flex="1 1 50%" height={0} width="100%">
          <TableView
            selectionMode={'multiple'}
            selectionStyle={'checkbox'}
            selectedKeys={keys}
            onSelectionChange={(keys) => setKeys(keys)}
            renderEmptyState={renderEmptyState}
            onAction={(key) => setDialog({ name: 'edit', fieldId: key.toString() })}
          >
            <TableHeader>
              <Column width={'15%'}>Name</Column>
              <Column>Beschreibung</Column>
              <Column>Gruppe</Column>
              <Column>Typ</Column>
              <Column>Verwendung</Column>
              <Column>Werte</Column>
              <Column>Aktiv</Column>
            </TableHeader>
            <TableBody items={fields}>
              {(emp) => (
                <Row key={emp.id}>
                  <Cell>{emp.name}</Cell>
                  <Cell>{emp.description}</Cell>
                  <Cell>{emp.group}</Cell>
                  <Cell>{FieldTypesMap[emp.type] || emp.type}</Cell>
                  <Cell>{FieldUsagesMap[emp.usage] || emp.usage}</Cell>
                  <Cell>{emp.valueCount}</Cell>
                  <Cell>
                    <Switch isReadOnly isSelected={emp.isActive} />
                  </Cell>
                </Row>
              )}
            </TableBody>
          </TableView>
          <ActionBar
            isEmphasized
            selectedItemCount={keys === 'all' ? 'all' : keys.size}
            onAction={onAction}
            onClearSelection={() => setKeys(new Set())}
          >
            <Item key="delete">
              <Delete />
              <Text>Löschen</Text>
            </Item>
          </ActionBar>
        </ActionBarContainer>
      </Flex>
    </Page>
  );
};
