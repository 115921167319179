import * as React from "react"
import { MandatorGateQuery } from "./MandatorGate.g"

type Me = MandatorGateQuery['me']
type MandatorList = Me['mandators']
type Mandator = Me["activeMandator"]


type MandatorContextType = {
  mandators: MandatorList
  selected?: Mandator
  setMandator: React.Dispatch<React.SetStateAction<string>>
}

export const MandatorContext = React.createContext<MandatorContextType>(null)
export const MandatorProvider = MandatorContext.Provider
export const MandatorConsumer = MandatorContext.Consumer