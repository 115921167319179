import * as React from 'react';
import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';

import { SpectrumTabsProps } from '@adobe/react-spectrum';

import { Tabs } from '@/components/UI';
import { RootState } from '@/store';

import { AppTab, activePathChanged } from './slice';

type AppTabsProps = SpectrumTabsProps<AppTab>;

export const AppTabs: React.FC<AppTabsProps> = (props) => {
  const location = useLocation();
  const state = useSelector((state: RootState) => state.appTabs);
  const match = useMatch(location.pathname);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      activePathChanged({
        pathname: location.pathname,
        search: location.search,
        hash: location.hash,
        title: location.state?.title?.toString() || location.pathname,
        icon: 'page',
      })
    );
  }, [location.pathname, location.hash, location.search]);

  return <Tabs {...props} selectedKey={state?.activePath} items={state.tabs} />;
};
