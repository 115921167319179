import React, { useEffect, useRef, useState } from 'react';

import {
  ActionButton,
  AlertDialog,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogContainer,
  DialogTrigger,
  Form,
  Key,
  Selection,
  TextField,
  useDialogContainer,
} from '@adobe/react-spectrum';
import { DOMRefValue } from '@react-types/shared';
import Add from '@spectrum-icons/workflow/Add';
import Delete from '@spectrum-icons/workflow/Delete';
import { ulid } from 'ulid';

import {
  ActionBar,
  ActionBarContainer,
  Cell,
  Column,
  Divider,
  Flex,
  Grid,
  Header,
  Heading,
  Item,
  ListView,
  Loading,
  Page,
  PageHeader,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
  View,
  Well,
} from '@/components/UI';

import {
  RolesDocument,
  RolesQueryResult,
  useDeleteRoleMutation,
  useRolesQuery,
  useScopesQuery,
  useUpdateOrInsertRoleMutation,
} from './Roles.g';

type Roles = RolesQueryResult['data']['roles'];
type Role = Roles[0];

export const RolesList: React.FC = () => {
  const [roleId, setRoleId] = useState(null);
  const [dialog, setDialog] = useState('');

  const { data: { scopes } = { scopes: [] }, loading: scopesLoading } = useScopesQuery();
  const { data: { roles } = { roles: [] }, loading: rolesLoading } = useRolesQuery();

  const role = roles.find((r) => r.id == roleId);

  const [updateOrInsertRole] = useUpdateOrInsertRoleMutation({
    refetchQueries: [RolesDocument],
  });

  const [deleteRole] = useDeleteRoleMutation({
    refetchQueries: [RolesDocument],
  });

  const upsertRole = (role: Role) => {
    updateOrInsertRole({
      variables: {
        input: {
          id: role.id,
          title: role.title,
          scopes: role.scopes,
        },
      },
      optimisticResponse: {
        updateOrInsertRole: {
          ...role,
        },
      },
    });
  };

  const onAction = (action: Key) => setDialog(action.toString());

  return (
    <Page title="Roles" icon="key" height="100%">
      <PageHeader onAction={onAction}>
        <Item key="create">
          <Add />
          <Text>Rolle</Text>
        </Item>
      </PageHeader>
      <DialogContainer onDismiss={() => setDialog('')}>{dialog == 'create' && <CreateRoleDialog />}</DialogContainer>
      <Grid areas={['roles permissions']} columns={['size-3600', '1fr']} height="100%" gap={'size-250'}>
        <View gridArea="roles">
          <Loading isLoading={rolesLoading}>
            {() => (
              <ListView
                aria-label="roles-list"
                selectionMode="single"
                selectionStyle="highlight"
                items={roles}
                selectedKeys={[roleId]}
                onSelectionChange={(key) => {
                  if (key != 'all') {
                    setRoleId(key.values().next().value);
                  }
                }}
              >
                {(item) => (
                  <Item key={item.id}>
                    <Text>{item.title}</Text>
                    {item.isSystem && <Text slot="description">Standardrolle</Text>}
                    {!item.isSystem && (
                      <ActionButton
                        onPress={() =>
                          deleteRole({
                            variables: {
                              id: item.id,
                            },
                          })
                        }
                      >
                        <Delete />
                      </ActionButton>
                    )}
                  </Item>
                )}
              </ListView>
            )}
          </Loading>
        </View>
        <View gridArea="permissions">
          <Loading isLoading={scopesLoading}>
            {() => (
              <ListView
                aria-label="Permissions Table"
                width="100%"
                selectionMode="multiple"
                selectedKeys={role?.scopes}
                disabledKeys={!roleId ? scopes.map((s) => s.name) : undefined}
                onSelectionChange={(keys) => {
                  const scopesToSet =
                    keys == 'all' ? scopes.map((s) => s.name) : Array.from(keys).map((s) => s.toString());

                  if (
                    role &&
                    (role.scopes.length != scopesToSet.length || role.scopes.every((s) => scopesToSet.indexOf(s) < 0))
                  ) {
                    upsertRole({
                      ...role,
                      scopes: scopesToSet,
                    });
                  }
                }}
                items={scopes}
              >
                {(scope) => (
                  <Item key={scope.name}>
                    {scope.parent && <Flex>↳</Flex>}
                    <Text>{scope.title}</Text>
                    <Text slot="description">{scope.description}</Text>
                  </Item>
                )}
              </ListView>
            )}
          </Loading>
        </View>
      </Grid>
    </Page>
  );
};

function CreateRoleDialog() {
  const dialog = useDialogContainer();
  const formRef = useRef<DOMRefValue<HTMLFormElement>>();
  const [newRoleName, setNewRoleName] = useState('');
  const [updateOrInsertRole] = useUpdateOrInsertRoleMutation({
    refetchQueries: [RolesDocument],
  });

  const createRole = () => {
    updateOrInsertRole({
      variables: {
        input: {
          id: ulid(),
          title: newRoleName,
          scopes: [],
        },
      },
      onCompleted: () => dialog.dismiss(),
      refetchQueries: [RolesDocument],
    });
  };

  return (
    <Dialog>
      <Heading>Rolle erstellen</Heading>
      <Divider />
      <Content>
        <Form
          validationBehavior="native"
          ref={formRef}
          onSubmit={(e) => {
            e.preventDefault();
            createRole();
          }}
        >
          <TextField width={'100%'} label="Name" isRequired autoFocus value={newRoleName} onChange={setNewRoleName} />
        </Form>
      </Content>
      <ButtonGroup>
        <Button variant="accent" type="button" onPress={() => formRef.current.UNSAFE_getDOMNode().requestSubmit()}>
          Rolle erstellen
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}
