import * as React from 'react';
import { PropsWithChildren } from 'react';

import { ProgressCircle } from '@adobe/react-spectrum';

type LoadingProps = {
    isLoading: boolean;
    children?: () => React.ReactNode | unknown;
};

export const Loading: React.FC<LoadingProps> = ({ isLoading, children }) => (
    <>
        {!!isLoading && <ProgressCircle isIndeterminate />}
        {!isLoading && children()}
    </>
);
