import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SpectrumTabListProps } from '@adobe/react-spectrum';
import Close from '@spectrum-icons/workflow/Close';

import { ActionGroup, Flex, Item, TabList, Text, View } from '@/components/UI';
import { RootState } from '@/store';

import { TabIcons } from './TabIcons';
import { AppTab, activePathChanged, tabClosed } from './slice';

type AppTabListProps = Omit<SpectrumTabListProps<AppTab>, 'children'>;

export const AppTabList: React.FC<AppTabListProps> = (props) => {
    const appTabs = useSelector((state: RootState) => state.appTabs);

    return (
        <TabList {...props} flex="1 1 auto" minWidth="0px">
            {(tab: AppTab) => {
                const Icon = TabIcons[tab.icon];

                return (
                    <Item
                        key={tab.pathname}
                        href={
                            tab.pathname +
                            (tab.hash ? '#' + tab.hash : '') +
                            (tab.search ? '?' + tab.search : '')
                        }
                    >
                        {Icon && <Icon />}
                        <Text>{tab.title}</Text>
                    </Item>
                );
            }}
        </TabList>
    );
};
