import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AlertDialog, ComboBox, DialogContainer, TextField } from '@adobe/react-spectrum';
import { Key } from '@react-types/shared';
import Cancel from '@spectrum-icons/workflow/Cancel';
import Delete from '@spectrum-icons/workflow/Delete';
import SaveTo from '@spectrum-icons/workflow/SaveTo';

import { Area, Flex, Item, Loading, PageHeader, Text } from '@/components/UI';
import { Page } from '@/components/UI/page';
import { UpdateEmployeeInput } from '@/gql';

import { FieldData, FieldEditor } from '../fields/FieldEditor';
import { FieldPartsFragment } from '../fields/Fields.g';
import {
  EmployeeDetailDocument,
  EmployeeDetailQueryResult,
  useDeleteEmployeesMutation,
  useEmployeeDetailQuery,
  useUpdateEmployeeMutation,
} from './Employees.g';
import { SubcontractorComboBox } from './SubcontractorComboBox';

type Employee = EmployeeDetailQueryResult['data']['employee'];

export const Employee: React.FC<React.PropsWithoutRef<{}>> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState<Partial<UpdateEmployeeInput>>({});
  const [dialog, setDialog] = useState('');
  const [fieldChanges, setFieldChanges] = useState<{ [key: string]: FieldData }>({});

  const hasChanges = Object.keys(fieldChanges).length > 0 || Object.keys(form).length > 0;

  const { data, loading, refetch } = useEmployeeDetailQuery({
    variables: { id },
  });

  const employee = data?.employee;
  const pageTitle = `${employee?.lastName}, ${employee?.firstName}`;

  const fields = data?.fields || [];
  const groupedField = fields.reduce<{ [key: string]: FieldPartsFragment[] }>((groups, field: FieldPartsFragment) => {
    if (groups[field.group] === undefined) {
      groups[field.group] = [];
    }

    groups[field.group].push(field);

    return groups;
  }, {});
  const groups = Object.keys(groupedField).filter((group) => group != staticGroup);

  const staticGroup = 'Persönliche Daten';

  const fieldData =
    employee?.fieldValues?.reduce<{ [key: string]: FieldData }>((values, item) => {
      values[item.fieldId] = item.data;
      return values;
    }, {}) || {};

  const modifiedFieldData = {
    ...fieldData,
    ...fieldChanges,
  };

  console.log({ fieldData, fieldChanges, modifiedFieldData });

  const [deleteEmployee] = useDeleteEmployeesMutation({
    variables: { input: { ids: [employee?.id] } },
    onCompleted: () => navigate('/employees'),
  });

  const [updateEmployeeMutation] = useUpdateEmployeeMutation({
    variables: {
      input: {
        id: employee?.id,
        ...form,
        fields: fieldChanges,
      },
    },
    onCompleted: () => {
      setForm({});
      setFieldChanges({});
    },
  });

  const onEmployeeChange = (input: Partial<Omit<UpdateEmployeeInput, 'id'>>) => {
    setForm(input);
  };

  const onFieldChange = (field: FieldPartsFragment, data: FieldData) => {
    setFieldChanges((changes) => ({ ...changes, [field.id]: data }));
  };

  const onAction = (action: Key) => {
    switch (action) {
      case 'reset':
        setForm({});
        setFieldChanges({});
        break;

      case 'save':
        updateEmployeeMutation();
        break;

      case 'delete':
        setDialog('delete');
        break;
    }
  };

  const employeeData = {
    ...(employee || {}),
    subcontractorId: employee?.subcontractor?.id,
    ...form,
  };

  return (
    <Loading isLoading={loading}>
      {() => (
        <Page title={pageTitle} icon="people" height="100%">
          <DialogContainer onDismiss={() => setDialog('')}>
            {dialog == 'delete' && (
              <AlertDialog
                title={`${employee?.lastName}, ${employee?.firstName} löschen?`}
                variant="destructive"
                primaryActionLabel="Löschen"
                cancelLabel="Abbrechen"
                onPrimaryAction={() => deleteEmployee()}
              >
                Möchten Sie den Mitarbeiter wirklich löschen? <br />
                Sofern Daten mit diesem Mitarbeiter verknüpft sind wird dieser nur deaktiviert.
              </AlertDialog>
            )}
          </DialogContainer>
          <PageHeader onAction={onAction} disabledActions={hasChanges ? ['delete'] : ['save', 'reset']}>
            <Item key={'save'}>
              <SaveTo />
              <Text>speichern</Text>
            </Item>

            <Item key={'reset'}>
              <Cancel />
              <Text>zurücksetzen</Text>
            </Item>

            <Item key={'delete'}>
              <Delete />
              <Text>löschen</Text>
            </Item>
          </PageHeader>
          <Flex direction={'row'} gap={'size-200'} wrap flex="1 1 100%" minHeight={0}>
            <Area flex="1 0 30%" title={staticGroup}>
              <Flex direction={'column'} gap={'size-200'}>
                <TextField
                  width={'100%'}
                  label="Vorname"
                  isRequired
                  value={employeeData.firstName}
                  onChange={(firstName) => onEmployeeChange({ firstName })}
                />
                <TextField
                  width={'100%'}
                  label="Vorname"
                  isRequired
                  value={employeeData.lastName}
                  onChange={(lastName) => onEmployeeChange({ lastName })}
                />
                <SubcontractorComboBox
                  width={'100%'}
                  isRequired
                  selectedKey={employeeData.subcontractorId}
                  onSelectionChange={(subcontractorId: string) => onEmployeeChange({ subcontractorId })}
                ></SubcontractorComboBox>
                <TextField
                  width={'100%'}
                  type="email"
                  label="E-Mail-Adresse"
                  isRequired
                  value={employeeData.email}
                  onChange={(email) => onEmployeeChange({ email })}
                />
                <TextField
                  width={'100%'}
                  type="externalId"
                  label="Mitarbeiternummer"
                  value={employeeData.externalId}
                  onChange={(externalId) => onEmployeeChange({ externalId })}
                />
                <FieldEditor
                  fieldData={modifiedFieldData}
                  fields={groupedField[staticGroup] || []}
                  onChange={onFieldChange}
                />
              </Flex>
            </Area>
            {groups.map((group) => (
              <Area key={group} flex="1 0 30%" maxWidth={'100%'} title={group || 'Sonstiges'}>
                <Flex direction={'column'} gap={'size-200'}>
                  <FieldEditor
                    fieldData={modifiedFieldData}
                    onChange={onFieldChange}
                    fields={groupedField[group] || []}
                  />
                </Flex>
              </Area>
            ))}
          </Flex>
        </Page>
      )}
    </Loading>
  );
};
