import * as Types from '../../gql';

import { gql } from '@apollo/client';
import { FieldPartsFragmentDoc } from '../fields/Fields.g';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeListPartsFragment = { __typename?: 'Employee', id: string, firstName: string, lastName: string, email?: string | null, externalId?: string | null, subcontractor?: { __typename?: 'Subcontractor', id: string, name: string } | null };

export type EmployeeDetailPartsFragment = { __typename?: 'Employee', id: string, firstName: string, lastName: string, email?: string | null, externalId?: string | null, subcontractor?: { __typename?: 'Subcontractor', id: string, name: string } | null };

export type EmployeesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EmployeesQuery = { __typename?: 'RootQuery', employees: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email?: string | null, externalId?: string | null, subcontractor?: { __typename?: 'Subcontractor', id: string, name: string } | null }>, fields: Array<{ __typename?: 'Field', id: string, type: string, name: string, fieldName: string, helpText: string, order: number, isActive: boolean, group: string, usage: string, description: string, settings: any, valueCount: number }> };

export type EmployeeSubcontractorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EmployeeSubcontractorsQuery = { __typename?: 'RootQuery', subcontractors: Array<{ __typename?: 'Subcontractor', id: string, name: string }> };

export type EmployeeDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type EmployeeDetailQuery = { __typename?: 'RootQuery', employee: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email?: string | null, externalId?: string | null, fieldValues: Array<{ __typename?: 'FieldValue', fieldId: string, data: any }>, subcontractor?: { __typename?: 'Subcontractor', id: string, name: string } | null }, fields: Array<{ __typename?: 'Field', id: string, type: string, name: string, fieldName: string, helpText: string, order: number, isActive: boolean, group: string, usage: string, description: string, settings: any, valueCount: number }> };

export type AddEmployeeMutationVariables = Types.Exact<{
  input: Types.AddEmployeeInput;
}>;


export type AddEmployeeMutation = { __typename?: 'RootMutation', addEmployee: { __typename?: 'Employee', id: string } | { __typename?: 'OperationInfo', messages: Array<{ __typename?: 'OperationMessage', kind: Types.OperationMessageKind, message: string, field?: string | null, code?: string | null }> } };

export type DeleteEmployeesMutationVariables = Types.Exact<{
  input: Types.DeleteEmployeesInput;
}>;


export type DeleteEmployeesMutation = { __typename?: 'RootMutation', deleteEmployees?: any | null };

export type UpdateEmployeeMutationVariables = Types.Exact<{
  input: Types.UpdateEmployeeInput;
}>;


export type UpdateEmployeeMutation = { __typename?: 'RootMutation', updateEmployee: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email?: string | null, externalId?: string | null, fieldValues: Array<{ __typename?: 'FieldValue', fieldId: string, data: any }>, subcontractor?: { __typename?: 'Subcontractor', id: string, name: string } | null } | { __typename?: 'OperationInfo' } };

export const EmployeeListPartsFragmentDoc = gql`
    fragment EmployeeListParts on Employee {
  id
  firstName
  lastName
  email
  externalId
  subcontractor {
    id
    name
  }
}
    `;
export const EmployeeDetailPartsFragmentDoc = gql`
    fragment EmployeeDetailParts on Employee {
  ...EmployeeListParts
}
    ${EmployeeListPartsFragmentDoc}`;
export const EmployeesDocument = gql`
    query Employees {
  employees {
    ...EmployeeListParts
  }
  fields(usage: "EMPLOYEE") {
    ...FieldParts
  }
}
    ${EmployeeListPartsFragmentDoc}
${FieldPartsFragmentDoc}`;

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
      }
export function useEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
        }
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>;
export type EmployeesLazyQueryHookResult = ReturnType<typeof useEmployeesLazyQuery>;
export type EmployeesQueryResult = Apollo.QueryResult<EmployeesQuery, EmployeesQueryVariables>;
export const EmployeeSubcontractorsDocument = gql`
    query EmployeeSubcontractors {
  subcontractors {
    id
    name
  }
}
    `;

/**
 * __useEmployeeSubcontractorsQuery__
 *
 * To run a query within a React component, call `useEmployeeSubcontractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSubcontractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeSubcontractorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeSubcontractorsQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeSubcontractorsQuery, EmployeeSubcontractorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeSubcontractorsQuery, EmployeeSubcontractorsQueryVariables>(EmployeeSubcontractorsDocument, options);
      }
export function useEmployeeSubcontractorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeSubcontractorsQuery, EmployeeSubcontractorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeSubcontractorsQuery, EmployeeSubcontractorsQueryVariables>(EmployeeSubcontractorsDocument, options);
        }
export type EmployeeSubcontractorsQueryHookResult = ReturnType<typeof useEmployeeSubcontractorsQuery>;
export type EmployeeSubcontractorsLazyQueryHookResult = ReturnType<typeof useEmployeeSubcontractorsLazyQuery>;
export type EmployeeSubcontractorsQueryResult = Apollo.QueryResult<EmployeeSubcontractorsQuery, EmployeeSubcontractorsQueryVariables>;
export const EmployeeDetailDocument = gql`
    query EmployeeDetail($id: ID!) {
  employee(id: $id) {
    ...EmployeeDetailParts
    fieldValues {
      fieldId
      data
    }
  }
  fields(usage: "EMPLOYEE") {
    ...FieldParts
  }
}
    ${EmployeeDetailPartsFragmentDoc}
${FieldPartsFragmentDoc}`;

/**
 * __useEmployeeDetailQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeDetailQuery(baseOptions: Apollo.QueryHookOptions<EmployeeDetailQuery, EmployeeDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeDetailQuery, EmployeeDetailQueryVariables>(EmployeeDetailDocument, options);
      }
export function useEmployeeDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeDetailQuery, EmployeeDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeDetailQuery, EmployeeDetailQueryVariables>(EmployeeDetailDocument, options);
        }
export type EmployeeDetailQueryHookResult = ReturnType<typeof useEmployeeDetailQuery>;
export type EmployeeDetailLazyQueryHookResult = ReturnType<typeof useEmployeeDetailLazyQuery>;
export type EmployeeDetailQueryResult = Apollo.QueryResult<EmployeeDetailQuery, EmployeeDetailQueryVariables>;
export const AddEmployeeDocument = gql`
    mutation AddEmployee($input: AddEmployeeInput!) {
  addEmployee(input: $input) {
    ... on Employee {
      id
    }
    ... on OperationInfo {
      messages {
        kind
        message
        field
        code
      }
    }
  }
}
    `;
export type AddEmployeeMutationFn = Apollo.MutationFunction<AddEmployeeMutation, AddEmployeeMutationVariables>;

/**
 * __useAddEmployeeMutation__
 *
 * To run a mutation, you first call `useAddEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmployeeMutation, { data, loading, error }] = useAddEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<AddEmployeeMutation, AddEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmployeeMutation, AddEmployeeMutationVariables>(AddEmployeeDocument, options);
      }
export type AddEmployeeMutationHookResult = ReturnType<typeof useAddEmployeeMutation>;
export type AddEmployeeMutationResult = Apollo.MutationResult<AddEmployeeMutation>;
export type AddEmployeeMutationOptions = Apollo.BaseMutationOptions<AddEmployeeMutation, AddEmployeeMutationVariables>;
export const DeleteEmployeesDocument = gql`
    mutation DeleteEmployees($input: DeleteEmployeesInput!) {
  deleteEmployees(input: $input)
}
    `;
export type DeleteEmployeesMutationFn = Apollo.MutationFunction<DeleteEmployeesMutation, DeleteEmployeesMutationVariables>;

/**
 * __useDeleteEmployeesMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeesMutation, { data, loading, error }] = useDeleteEmployeesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeesMutation, DeleteEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeesMutation, DeleteEmployeesMutationVariables>(DeleteEmployeesDocument, options);
      }
export type DeleteEmployeesMutationHookResult = ReturnType<typeof useDeleteEmployeesMutation>;
export type DeleteEmployeesMutationResult = Apollo.MutationResult<DeleteEmployeesMutation>;
export type DeleteEmployeesMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeesMutation, DeleteEmployeesMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(input: $input) {
    ...EmployeeDetailParts
    ... on Employee {
      fieldValues {
        fieldId
        data
      }
    }
  }
}
    ${EmployeeDetailPartsFragmentDoc}`;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;