import React, { ReactElement, ReactNode, useState } from 'react';

import { DialogContainer, SpectrumDialogProps } from '@adobe/react-spectrum';

type Dialogs = {
  [key: string]: () => ReactElement;
};

type DialogName = keyof Dialogs;

export function useDialogs(dialogs: Dialogs) {
  const [dialog, setDialog] = useState<DialogName>();

  function showDialog(dialog: DialogName) {
    setDialog(dialog);
  }

  function dismissDialog() {
    setDialog(null);
  }

  const DialogComponent = dialogs[dialog];

  return {
    Dialogs: () => (
      <DialogContainer onDismiss={dismissDialog}>{DialogComponent && <DialogComponent />}</DialogContainer>
    ),
    showDialog,
    dismissDialog,
  };
}
