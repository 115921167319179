import * as React from 'react';
import { useContext } from 'react';
import { usePress } from 'react-aria';

import { useQuery } from '@apollo/client';

import {
    ActionButton,
    Button,
    ButtonGroup,
    Content,
    Dialog,
    DialogTrigger,
    Divider,
    Flex,
    Footer,
    Heading,
    Item,
    ListView,
    View,
} from '@/components/UI';

import { MandatorContext, MandatorSelectDialog } from '../mandator-gate';
import { useUserBoxQuery } from './UserBox.g';

export const UserBox: React.FC<React.PropsWithoutRef<{}>> = () => {
    const [selectOpen, setSelectOpen] = React.useState(false);
    const mandator = useContext(MandatorContext);
    const { pressProps } = usePress({ onPress: () => setSelectOpen(true) });

    const { data: { me } = {} } = useUserBoxQuery({
        fetchPolicy: 'cache-first',
    });

    return (
        <DialogTrigger
            isOpen={selectOpen}
            onOpenChange={(isOpen) => setSelectOpen(isOpen)}
        >
            <div {...pressProps}>
                <Flex
                    gap={'size-100'}
                    margin={'size-100'}
                    alignItems={'center'}
                >
                    <Flex direction={'column'} margin={'size-50'}>
                        <Heading level={4} margin={0}>
                            {me?.name}
                        </Heading>
                        <small>{me?.activeMandator?.name}</small>
                    </Flex>
                </Flex>
            </div>
            {(close) => (
                <MandatorSelectDialog
                    isDismissable
                    onSelected={() => close()}
                />
            )}
        </DialogTrigger>
    );
};
