import React, { useState } from 'react';

import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Form,
  Heading,
  ProgressCircle,
  Switch,
  TextArea,
  useDialogContainer,
} from '@adobe/react-spectrum';

import { usePositionNoteQuery, useUpdatePositionMutation } from './Plans.g';

type EditHintDialogProps = {
  id: string;
};

export function EditHintDialog({ id }: EditHintDialogProps) {
  const [form, setForm] = useState({});

  const container = useDialogContainer();
  const query = usePositionNoteQuery({ variables: { id, ...form } });
  const [updatePosition, mutation] = useUpdatePositionMutation({
    variables: {
      input: {
        positionId: id,
        ...form,
      },
    },
    onCompleted: () => container.dismiss(),
  });

  const position = {
    ...(query.data && query.data.position),
    ...form,
  };

  return (
    <Dialog size="S">
      <Heading>Bemerkung</Heading>
      <Divider />
      <Content>
        {query.loading ? (
          <ProgressCircle />
        ) : (
          <Form>
            <TextArea
              width={'100%'}
              value={position.note}
              onChange={(note) => setForm((form) => ({ ...form, note }))}
            />
            <Switch
              isSelected={position.noteIsImportant}
              onChange={(noteIsImportant) => setForm((form) => ({ ...form, noteIsImportant }))}
            >
              Hervorheben
            </Switch>
          </Form>
        )}
      </Content>
      <ButtonGroup isDisabled={query.loading}>
        <Button variant={'secondary'} onPress={container.dismiss}>
          Abbrechen
        </Button>
        <Button isPending={mutation.loading} variant={'accent'} onPress={() => updatePosition()}>
          Speichern
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}
