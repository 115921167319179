import * as Types from '../../gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubcontractorPartsFragment = { __typename?: 'Subcontractor', id: string, name: string, address: string, active: boolean };

export type SubcontractorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SubcontractorsQuery = { __typename?: 'RootQuery', subcontractors: Array<{ __typename?: 'Subcontractor', id: string, name: string, address: string, active: boolean }> };

export type SubcontractorQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type SubcontractorQuery = { __typename?: 'RootQuery', subcontractor: { __typename?: 'Subcontractor', id: string, name: string, address: string, active: boolean } };

export type CreateSubcontractorMutationVariables = Types.Exact<{
  input: Types.CreateSubcontractorInput;
}>;


export type CreateSubcontractorMutation = { __typename?: 'RootMutation', createSubcontractor: { __typename?: 'Subcontractor', id: string, name: string, address: string, active: boolean } };

export type UpdateSubcontractorMutationVariables = Types.Exact<{
  input: Types.UpdateSubcontractorInput;
}>;


export type UpdateSubcontractorMutation = { __typename?: 'RootMutation', updateSubcontractor: { __typename?: 'Subcontractor', id: string, name: string, address: string, active: boolean } };

export type DeleteSubcontractorMutationVariables = Types.Exact<{
  input: Types.DeleteSubcontractorInput;
}>;


export type DeleteSubcontractorMutation = { __typename?: 'RootMutation', deleteSubcontractor?: any | null };

export const SubcontractorPartsFragmentDoc = gql`
    fragment SubcontractorParts on Subcontractor {
  id
  name
  address
  active
}
    `;
export const SubcontractorsDocument = gql`
    query Subcontractors {
  subcontractors {
    ...SubcontractorParts
  }
}
    ${SubcontractorPartsFragmentDoc}`;

/**
 * __useSubcontractorsQuery__
 *
 * To run a query within a React component, call `useSubcontractorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubcontractorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubcontractorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubcontractorsQuery(baseOptions?: Apollo.QueryHookOptions<SubcontractorsQuery, SubcontractorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubcontractorsQuery, SubcontractorsQueryVariables>(SubcontractorsDocument, options);
      }
export function useSubcontractorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubcontractorsQuery, SubcontractorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubcontractorsQuery, SubcontractorsQueryVariables>(SubcontractorsDocument, options);
        }
export type SubcontractorsQueryHookResult = ReturnType<typeof useSubcontractorsQuery>;
export type SubcontractorsLazyQueryHookResult = ReturnType<typeof useSubcontractorsLazyQuery>;
export type SubcontractorsQueryResult = Apollo.QueryResult<SubcontractorsQuery, SubcontractorsQueryVariables>;
export const SubcontractorDocument = gql`
    query Subcontractor($id: ID!) {
  subcontractor(id: $id) {
    ...SubcontractorParts
  }
}
    ${SubcontractorPartsFragmentDoc}`;

/**
 * __useSubcontractorQuery__
 *
 * To run a query within a React component, call `useSubcontractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubcontractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubcontractorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubcontractorQuery(baseOptions: Apollo.QueryHookOptions<SubcontractorQuery, SubcontractorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubcontractorQuery, SubcontractorQueryVariables>(SubcontractorDocument, options);
      }
export function useSubcontractorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubcontractorQuery, SubcontractorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubcontractorQuery, SubcontractorQueryVariables>(SubcontractorDocument, options);
        }
export type SubcontractorQueryHookResult = ReturnType<typeof useSubcontractorQuery>;
export type SubcontractorLazyQueryHookResult = ReturnType<typeof useSubcontractorLazyQuery>;
export type SubcontractorQueryResult = Apollo.QueryResult<SubcontractorQuery, SubcontractorQueryVariables>;
export const CreateSubcontractorDocument = gql`
    mutation CreateSubcontractor($input: CreateSubcontractorInput!) {
  createSubcontractor(input: $input) {
    ...SubcontractorParts
  }
}
    ${SubcontractorPartsFragmentDoc}`;
export type CreateSubcontractorMutationFn = Apollo.MutationFunction<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>;

/**
 * __useCreateSubcontractorMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractorMutation, { data, loading, error }] = useCreateSubcontractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractorMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>(CreateSubcontractorDocument, options);
      }
export type CreateSubcontractorMutationHookResult = ReturnType<typeof useCreateSubcontractorMutation>;
export type CreateSubcontractorMutationResult = Apollo.MutationResult<CreateSubcontractorMutation>;
export type CreateSubcontractorMutationOptions = Apollo.BaseMutationOptions<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>;
export const UpdateSubcontractorDocument = gql`
    mutation UpdateSubcontractor($input: UpdateSubcontractorInput!) {
  updateSubcontractor(input: $input) {
    ...SubcontractorParts
  }
}
    ${SubcontractorPartsFragmentDoc}`;
export type UpdateSubcontractorMutationFn = Apollo.MutationFunction<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>;

/**
 * __useUpdateSubcontractorMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractorMutation, { data, loading, error }] = useUpdateSubcontractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>(UpdateSubcontractorDocument, options);
      }
export type UpdateSubcontractorMutationHookResult = ReturnType<typeof useUpdateSubcontractorMutation>;
export type UpdateSubcontractorMutationResult = Apollo.MutationResult<UpdateSubcontractorMutation>;
export type UpdateSubcontractorMutationOptions = Apollo.BaseMutationOptions<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>;
export const DeleteSubcontractorDocument = gql`
    mutation DeleteSubcontractor($input: DeleteSubcontractorInput!) {
  deleteSubcontractor(input: $input)
}
    `;
export type DeleteSubcontractorMutationFn = Apollo.MutationFunction<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>;

/**
 * __useDeleteSubcontractorMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractorMutation, { data, loading, error }] = useDeleteSubcontractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSubcontractorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>(DeleteSubcontractorDocument, options);
      }
export type DeleteSubcontractorMutationHookResult = ReturnType<typeof useDeleteSubcontractorMutation>;
export type DeleteSubcontractorMutationResult = Apollo.MutationResult<DeleteSubcontractorMutation>;
export type DeleteSubcontractorMutationOptions = Apollo.BaseMutationOptions<DeleteSubcontractorMutation, DeleteSubcontractorMutationVariables>;