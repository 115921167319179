import * as React from 'react';
import { Key, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Content, Heading, InlineAlert, SearchField } from '@adobe/react-spectrum';
import { parseDate } from '@internationalized/date';
import { Selection } from '@react-types/shared';
import Add from '@spectrum-icons/workflow/Add';
import Alert from '@spectrum-icons/workflow/Alert';
import IconChevronLeft from '@spectrum-icons/workflow/ChevronLeft';
import IconChevronRight from '@spectrum-icons/workflow/ChevronRight';
import Copy from '@spectrum-icons/workflow/Copy';
import Delete from '@spectrum-icons/workflow/Delete';
import Edit from '@spectrum-icons/workflow/Edit';
import Import from '@spectrum-icons/workflow/Import';

import { ActionBarContainer, Button, DateInput, ErrorBoundary, Flex, Item, PageHeader, Text } from '@/components/UI';
import { ActionBar, Page } from '@/components/UI';

import { useDialogs } from '../dialogs/hooks';
import { EditPlanDialog } from './EditPlanDialog';
import { PlanTable } from './PlanTable';
import {
  PlanDocument,
  useCopyPositionMutation,
  useCreatePositionMutation,
  useDeletePositionMutation,
  usePlanQuery,
} from './Plans.g';

export const Plan: React.FC<React.PropsWithoutRef<{}>> = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState('');
  const [keys, setKeys] = useState<Selection>(new Set([]));

  const deferredSearch = React.useDeferredValue(search);

  const { Dialogs, showDialog, dismissDialog } = useDialogs({
    edit: () => <EditPlanDialog id={id} />,
  });

  const date = searchParams.get('date');

  const setDate = (date: string) => {
    setSearchParams({ date });
  };

  const setNextDay = () => setDate(parseDate(date).add({ days: 1 }).toString());
  const setPreviousDay = () => setDate(parseDate(date).subtract({ days: 1 }).toString());

  const result = usePlanQuery({
    variables: { id, date },
    fetchPolicy: 'cache-and-network',
  });

  const { data, previousData } = result;

  const plan = data?.plan || previousData?.plan;

  const { positions = [] } = plan || {};

  const positionIds = keys == 'all' ? positions.map((p) => p.id) || [] : Array.from(keys).map((k) => k.toString());

  useEffect(() => {
    if (plan && !date) {
      setDate(plan.start);
    }
  }, [plan, date]);

  const [createPosition] = useCreatePositionMutation({
    variables: {
      input: { planId: id, date },
    },
    refetchQueries: [PlanDocument],
  });

  const [copyPosition] = useCopyPositionMutation({
    variables: {
      input: { positionIds },
    },
    onCompleted: () => setKeys(new Set()),
    refetchQueries: [PlanDocument],
  });

  const [deletePosition] = useDeletePositionMutation({
    variables: {
      input: { positionIds },
    },
    onCompleted: () => setKeys(new Set()),
    refetchQueries: [PlanDocument],
  });

  const onTableAction = (key: Key) => {
    switch (key) {
      case 'copy':
        copyPosition();
        break;
      case 'delete':
        deletePosition();
        break;
    }
  };

  const onAction = (action: Key) => {
    switch (action) {
      case 'create':
        return createPosition();

      case 'edit':
      case 'fields':
        showDialog(action);
    }
  };

  return (
    <Page title={plan?.name} icon="calendar" height="100%">
      <Dialogs />
      <PageHeader
        onAction={onAction}
        disabledActions={['import']}
        search={<SearchField value={search} onChange={setSearch} />}
        secondary={
          <Flex gap={'size-200'}>
            <Flex gap="size-50">
              <DateInput
                aria-label="date"
                minValue={plan?.start && parseDate(plan.start)}
                maxValue={plan?.end && parseDate(plan.end)}
                value={date ? parseDate(date) : null}
                onChange={(date) => setDate(date.toString())}
              />

              <Button variant="secondary" onPress={() => setPreviousDay()}>
                <IconChevronLeft />
              </Button>
              <Button variant="secondary" onPress={() => setNextDay()}>
                <IconChevronRight />
              </Button>
            </Flex>
          </Flex>
        }
      >
        <Item key="create">
          <Add />
          <Text>Position</Text>
        </Item>
        <Item key="edit">
          <Edit />
          <Text>Bearbeiten</Text>
        </Item>
        <Item key="import">
          <Import />
          <Text>Importieren</Text>
        </Item>
      </PageHeader>
      {plan?.hint && (
        <InlineAlert variant="notice">
          <Heading>Hinweis</Heading>
          <Content>{plan.hint}</Content>
        </InlineAlert>
      )}
      <ErrorBoundary>
        <ActionBarContainer flex="1 1 50%" height={0} width="100%">
          <PlanTable
            search={deferredSearch}
            result={result}
            selectedKeys={keys}
            onSelectionChange={(keys) => setKeys(keys)}
          />
          <ActionBar
            isEmphasized
            selectedItemCount={keys === 'all' ? 'all' : keys.size}
            onAction={onTableAction}
            onClearSelection={() => setKeys(new Set())}
          >
            <Item key="copy">
              <Copy />
              <Text>Kopieren</Text>
            </Item>
            <Item key="delete">
              <Delete />
              <Text>Löschen</Text>
            </Item>
          </ActionBar>
        </ActionBarContainer>
      </ErrorBoundary>
    </Page>
  );
};
