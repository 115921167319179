import * as Types from '../../gql';

import { gql } from '@apollo/client';
import { OperationInfoPartsFragmentDoc } from '../errors/Errros.g';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FieldPartsFragment = { __typename?: 'Field', id: string, type: string, name: string, fieldName: string, helpText: string, order: number, isActive: boolean, group: string, usage: string, description: string, settings: any, valueCount: number };

export type FieldsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FieldsQuery = { __typename?: 'RootQuery', fields: Array<{ __typename?: 'Field', id: string, type: string, name: string, fieldName: string, helpText: string, order: number, isActive: boolean, group: string, usage: string, description: string, settings: any, valueCount: number }> };

export type FieldValuesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type FieldValuesQuery = { __typename?: 'RootQuery', field: { __typename?: 'Field', id: string, values: Array<any> } };

export type FieldQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type FieldQuery = { __typename?: 'RootQuery', field: { __typename?: 'Field', id: string, type: string, name: string, fieldName: string, helpText: string, order: number, isActive: boolean, group: string, usage: string, description: string, settings: any, valueCount: number } };

export type UpdateFieldMutationVariables = Types.Exact<{
  input: Types.UpdateFieldInput;
}>;


export type UpdateFieldMutation = { __typename?: 'RootMutation', updateField: { __typename?: 'Field', id: string, type: string, name: string, fieldName: string, helpText: string, order: number, isActive: boolean, group: string, usage: string, description: string, settings: any, valueCount: number } };

export type CreateFieldMutationVariables = Types.Exact<{
  input: Types.CreateFieldInput;
}>;


export type CreateFieldMutation = { __typename?: 'RootMutation', createField: { __typename?: 'Field', id: string, type: string, name: string, fieldName: string, helpText: string, order: number, isActive: boolean, group: string, usage: string, description: string, settings: any, valueCount: number } | { __typename?: 'OperationInfo', messages: Array<{ __typename?: 'OperationMessage', kind: Types.OperationMessageKind, message: string, field?: string | null, code?: string | null }> } };

export type DeleteFieldsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type DeleteFieldsMutation = { __typename?: 'RootMutation', deleteFields?: any | null };

export const FieldPartsFragmentDoc = gql`
    fragment FieldParts on Field {
  id
  type
  name
  fieldName
  helpText
  order
  isActive
  group
  usage
  description
  settings
  valueCount
}
    `;
export const FieldsDocument = gql`
    query Fields {
  fields {
    ...FieldParts
  }
}
    ${FieldPartsFragmentDoc}`;

/**
 * __useFieldsQuery__
 *
 * To run a query within a React component, call `useFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFieldsQuery(baseOptions?: Apollo.QueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
      }
export function useFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
        }
export type FieldsQueryHookResult = ReturnType<typeof useFieldsQuery>;
export type FieldsLazyQueryHookResult = ReturnType<typeof useFieldsLazyQuery>;
export type FieldsQueryResult = Apollo.QueryResult<FieldsQuery, FieldsQueryVariables>;
export const FieldValuesDocument = gql`
    query FieldValues($id: ID!) {
  field(id: $id) {
    id
    values
  }
}
    `;

/**
 * __useFieldValuesQuery__
 *
 * To run a query within a React component, call `useFieldValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldValuesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFieldValuesQuery(baseOptions: Apollo.QueryHookOptions<FieldValuesQuery, FieldValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldValuesQuery, FieldValuesQueryVariables>(FieldValuesDocument, options);
      }
export function useFieldValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldValuesQuery, FieldValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldValuesQuery, FieldValuesQueryVariables>(FieldValuesDocument, options);
        }
export type FieldValuesQueryHookResult = ReturnType<typeof useFieldValuesQuery>;
export type FieldValuesLazyQueryHookResult = ReturnType<typeof useFieldValuesLazyQuery>;
export type FieldValuesQueryResult = Apollo.QueryResult<FieldValuesQuery, FieldValuesQueryVariables>;
export const FieldDocument = gql`
    query Field($id: ID!) {
  field(id: $id) {
    ...FieldParts
  }
}
    ${FieldPartsFragmentDoc}`;

/**
 * __useFieldQuery__
 *
 * To run a query within a React component, call `useFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFieldQuery(baseOptions: Apollo.QueryHookOptions<FieldQuery, FieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldQuery, FieldQueryVariables>(FieldDocument, options);
      }
export function useFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldQuery, FieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldQuery, FieldQueryVariables>(FieldDocument, options);
        }
export type FieldQueryHookResult = ReturnType<typeof useFieldQuery>;
export type FieldLazyQueryHookResult = ReturnType<typeof useFieldLazyQuery>;
export type FieldQueryResult = Apollo.QueryResult<FieldQuery, FieldQueryVariables>;
export const UpdateFieldDocument = gql`
    mutation UpdateField($input: UpdateFieldInput!) {
  updateField(input: $input) {
    ...FieldParts
  }
}
    ${FieldPartsFragmentDoc}`;
export type UpdateFieldMutationFn = Apollo.MutationFunction<UpdateFieldMutation, UpdateFieldMutationVariables>;

/**
 * __useUpdateFieldMutation__
 *
 * To run a mutation, you first call `useUpdateFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldMutation, { data, loading, error }] = useUpdateFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFieldMutation, UpdateFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFieldMutation, UpdateFieldMutationVariables>(UpdateFieldDocument, options);
      }
export type UpdateFieldMutationHookResult = ReturnType<typeof useUpdateFieldMutation>;
export type UpdateFieldMutationResult = Apollo.MutationResult<UpdateFieldMutation>;
export type UpdateFieldMutationOptions = Apollo.BaseMutationOptions<UpdateFieldMutation, UpdateFieldMutationVariables>;
export const CreateFieldDocument = gql`
    mutation CreateField($input: CreateFieldInput!) {
  createField(input: $input) {
    ...FieldParts
    ...OperationInfoParts
  }
}
    ${FieldPartsFragmentDoc}
${OperationInfoPartsFragmentDoc}`;
export type CreateFieldMutationFn = Apollo.MutationFunction<CreateFieldMutation, CreateFieldMutationVariables>;

/**
 * __useCreateFieldMutation__
 *
 * To run a mutation, you first call `useCreateFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFieldMutation, { data, loading, error }] = useCreateFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateFieldMutation, CreateFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFieldMutation, CreateFieldMutationVariables>(CreateFieldDocument, options);
      }
export type CreateFieldMutationHookResult = ReturnType<typeof useCreateFieldMutation>;
export type CreateFieldMutationResult = Apollo.MutationResult<CreateFieldMutation>;
export type CreateFieldMutationOptions = Apollo.BaseMutationOptions<CreateFieldMutation, CreateFieldMutationVariables>;
export const DeleteFieldsDocument = gql`
    mutation DeleteFields($ids: [ID!]!) {
  deleteFields(ids: $ids)
}
    `;
export type DeleteFieldsMutationFn = Apollo.MutationFunction<DeleteFieldsMutation, DeleteFieldsMutationVariables>;

/**
 * __useDeleteFieldsMutation__
 *
 * To run a mutation, you first call `useDeleteFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFieldsMutation, { data, loading, error }] = useDeleteFieldsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteFieldsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFieldsMutation, DeleteFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFieldsMutation, DeleteFieldsMutationVariables>(DeleteFieldsDocument, options);
      }
export type DeleteFieldsMutationHookResult = ReturnType<typeof useDeleteFieldsMutation>;
export type DeleteFieldsMutationResult = Apollo.MutationResult<DeleteFieldsMutation>;
export type DeleteFieldsMutationOptions = Apollo.BaseMutationOptions<DeleteFieldsMutation, DeleteFieldsMutationVariables>;