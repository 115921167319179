import * as React from 'react';
import { FaBuildingColumns } from 'react-icons/fa6';

import { Flex, Icon, Item, ListBox, Section, Text, View } from '@adobe/react-spectrum';
import Building from '@spectrum-icons/workflow/Building';
import Calendar from '@spectrum-icons/workflow/Calendar';
import Hammer from '@spectrum-icons/workflow/Hammer';
import Key from '@spectrum-icons/workflow/Key';
import Money from '@spectrum-icons/workflow/Money';
import PeopleGroup from '@spectrum-icons/workflow/PeopleGroup';
import Stamp from '@spectrum-icons/workflow/Stamp';
import UserArrow from '@spectrum-icons/workflow/UserArrow';

export default () => {
  return (
    <Flex flex="1 1 100%" justifyContent={'space-between'} direction={'column'}>
      <View padding={'size-100'}>
        <ListBox aria-label="Links">
          <Item href="/plans" key="/plans">
            <Calendar />
            <Text>Pläne</Text>
          </Item>
          <Item href="/employees" key="/employees">
            <PeopleGroup />
            <Text>Mitarbeiter</Text>
          </Item>
          <Item href="/subcontractors" key="/subcontractors">
            <UserArrow />
            <Text>Subunternehmer</Text>
          </Item>
          <Item key="/rates" href="/rates">
            <Money />
            <Text>Stundensätze</Text>
          </Item>
        </ListBox>
      </View>
      <View padding={'size-100'}>
        <ListBox aria-label="Links" disabledKeys={['/settings/rollen']}>
          <Section title="Einstellungen">
            <Item key="/settings/fields" href="/settings/fields">
              <Stamp />
              <Text>Felder</Text>
            </Item>
            <Item href="/settings/rollen">
              <Key />
              <Text>Rollen</Text>
            </Item>
          </Section>
        </ListBox>
      </View>
    </Flex>
  );
};
