import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';

import {
    ActionButton,
    Content,
    Dialog,
    DialogContainer,
    DialogTrigger,
    Heading,
    Item,
    ListView,
} from '@/components/UI';

import { useMandatorGateQuery } from './MandatorGate.g';
import { MandatorProvider } from './MandatorProvider';
import { MandatorSelectDialog } from './MandatorSelectDialog';

const MANDATOR_STORAGE_KEY = 'selectedMandatorId';

export const getMandator = () => {
    return sessionStorage.getItem(MANDATOR_STORAGE_KEY);
};

export const MandatorGate: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const { data: { me } = {}, refetch } = useMandatorGateQuery({
        fetchPolicy: 'cache-first',
    });

    const setMandator = (id: string) => {
        sessionStorage.setItem(MANDATOR_STORAGE_KEY, id);
        refetch();
    };

    const context = useMemo(
        () => ({
            setMandator,
            mandators: me?.mandators || [],
            selected: me?.activeMandator,
        }),
        [me],
    );

    return (
        <MandatorProvider value={context}>
            <DialogTrigger isOpen={!me?.activeMandator}>
                <ActionButton isHidden>Mandant auswählen</ActionButton>
                <MandatorSelectDialog />
            </DialogTrigger>
            {children}
        </MandatorProvider>
    );
};

export default MandatorGate;
