import * as React from 'react';

import { StyleProps } from '@react-types/shared';

import { Content } from '../content/Content';
import { Heading } from '../heading/Heading';
import { View } from '../view/View';
import styles from './Area.css';

type AreaProps = {
    title: string;
} & StyleProps;

export const Area: React.FC<React.PropsWithChildren<AreaProps>> = (props) => {
    const { title, children, ...rest } = props;

    return (
        <View
            borderWidth="thin"
            borderColor="dark"
            borderRadius="regular"
            padding="size-200"
            minWidth={'size-5000'}
            {...rest}
        >
            <Heading level={3} marginTop={0} marginBottom={'size-100'}>
                {title}
            </Heading>
            <Content>{children}</Content>
        </View>
    );
};
