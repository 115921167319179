import * as Types from '../../gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserBoxQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserBoxQuery = { __typename?: 'RootQuery', me?: { __typename?: 'User', id: string, name: string, activeMandator?: { __typename?: 'Mandator', id: string, name: string } | null } | null };


export const UserBoxDocument = gql`
    query UserBox {
  me {
    id
    name
    activeMandator {
      id
      name
    }
  }
}
    `;

/**
 * __useUserBoxQuery__
 *
 * To run a query within a React component, call `useUserBoxQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBoxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBoxQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserBoxQuery(baseOptions?: Apollo.QueryHookOptions<UserBoxQuery, UserBoxQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserBoxQuery, UserBoxQueryVariables>(UserBoxDocument, options);
      }
export function useUserBoxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserBoxQuery, UserBoxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserBoxQuery, UserBoxQueryVariables>(UserBoxDocument, options);
        }
export type UserBoxQueryHookResult = ReturnType<typeof useUserBoxQuery>;
export type UserBoxLazyQueryHookResult = ReturnType<typeof useUserBoxLazyQuery>;
export type UserBoxQueryResult = Apollo.QueryResult<UserBoxQuery, UserBoxQueryVariables>;