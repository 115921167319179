import * as React from 'react';
import { useRef, useState } from 'react';
import { FocusRing, useButton, useFilter, usePress } from 'react-aria';
import { FaRegFaceDizzy } from 'react-icons/fa6';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortDescriptor } from 'react-stately';

import {
  ActionButton,
  ComboBox,
  Content,
  DialogContainer,
  Divider,
  Picker,
  SearchField,
  Selection,
  ToggleButton,
  View,
} from '@adobe/react-spectrum';
import NotFound from '@spectrum-icons/illustrations/NotFound';
import Add from '@spectrum-icons/workflow/Add';
import Copy from '@spectrum-icons/workflow/Copy';
import Delete from '@spectrum-icons/workflow/Delete';
import Edit from '@spectrum-icons/workflow/Edit';
import Filter from '@spectrum-icons/workflow/Filter';
import { addMinutes } from 'date-fns';

import {
  ActionBar,
  ActionBarContainer,
  Area,
  Button,
  ButtonGroup,
  Cell,
  Column,
  Dialog,
  DialogTrigger,
  Flex,
  Header,
  Heading,
  IllustratedMessage,
  Item,
  PageHeader,
  Row,
  SearchInput,
  StatusLight,
  Switch,
  TableBody,
  TableHeader,
  TableView,
  Text,
} from '@/components/UI';
import { Page } from '@/components/UI/page';

import { FieldFilter } from '../fields/FieldFilter';
import { CreateEmployeeDialog } from './CreateEmployeeDialog';
import { EmployeesDocument, useDeleteEmployeesMutation, useEmployeesQuery } from './Employees.g';

function renderEmptyState() {
  return (
    <IllustratedMessage>
      <NotFound />
      <Heading>Keine Mitarbeiter gefunden</Heading>
    </IllustratedMessage>
  );
}

type TextButtonProps = {
  onPress?: () => void;
} & React.PropsWithChildren;

export const EmployeeList: React.FC<React.PropsWithoutRef<{}>> = () => {
  const { contains } = useFilter({
    sensitivity: 'base',
  });

  const [search, setSearch] = useState('');

  const [dialog, setDialog] = useState('');
  const [sort, setSort] = useState<SortDescriptor>();
  const navigate = useNavigate();
  const { data: { employees, fields } = {} } = useEmployeesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [deleteEmployees] = useDeleteEmployeesMutation({
    refetchQueries: [EmployeesDocument],
  });

  const matchedEmployees = employees?.filter((employee) =>
    contains(
      [
        employee.email,
        employee.externalId,
        employee.firstName,
        employee.lastName,
        employee.subcontractor?.name || '',
      ].join(' '),
      search
    )
  );

  return (
    <Page title="Mitarbeiter" icon="people" height="100%">
      <PageHeader
        onAction={(key) => setDialog(key.toString())}
        search={<SearchField value={search} onChange={setSearch} />}
      >
        <Item key="create">
          <Add />
          <Text>Mitarbeiter</Text>
        </Item>
      </PageHeader>
      <TableView
        renderEmptyState={renderEmptyState}
        onSortChange={setSort}
        sortDescriptor={sort}
        onAction={(key) => navigate(`/employees/${key}`)}
      >
        <TableHeader>
          <Column allowsSorting>Nachname</Column>
          <Column allowsSorting>Vorname</Column>
          <Column width={'15%'} allowsSorting>
            Mitarbeiternr.
          </Column>
          <Column allowsSorting>E-Mail</Column>
          <Column allowsSorting>Dienstleister</Column>
        </TableHeader>
        <TableBody items={matchedEmployees || []}>
          {(emp) => (
            <Row key={emp.id}>
              <Cell>{emp.lastName}</Cell>
              <Cell>{emp.firstName}</Cell>
              <Cell>{emp.externalId}</Cell>
              <Cell>{emp.email}</Cell>
              <Cell>{emp.subcontractor?.name}</Cell>
            </Row>
          )}
        </TableBody>
      </TableView>

      <DialogContainer onDismiss={() => setDialog('')}>
        {dialog == 'create' && <CreateEmployeeDialog />}
      </DialogContainer>
    </Page>
  );
};
