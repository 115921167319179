import { configureStore, combineReducers, Middleware, PayloadAction } from '@reduxjs/toolkit'

import appTabs, {defaultInitialState as appTabsInitialState, appTabsMiddleware} from "@/features/app-tabs/slice"

const rootReducer = combineReducers({
  appTabs
})

export type RootState = ReturnType<typeof rootReducer>



const store = configureStore({
  reducer: rootReducer,
  preloadedState: {
    appTabs: JSON.parse(sessionStorage.getItem("appTabs")) || appTabsInitialState
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(appTabsMiddleware)
})

export default store

