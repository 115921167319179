import React from 'react';

import {
    ViewProps as ViewPropsSpectrum,
    View as ViewSpectrum,
} from '@adobe/react-spectrum';
import { ColorVersion } from '@react-types/shared';

interface ViewProps extends ViewPropsSpectrum<ColorVersion> {}

export const View: React.FC<ViewProps> = (props) => <ViewSpectrum {...props} />;
