import React from 'react';
import { ListBox } from 'react-aria-components';
import { createRoot } from 'react-dom/client';
import { useNavigate } from 'react-router-dom';

import {
  ActionButton,
  Avatar,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Flex,
  Footer,
  Heading,
  Item,
  ListView,
  Picker,
  Text,
  View,
} from '@adobe/react-spectrum';

import { Slot } from '@/components/UI/slot';
import { Shell } from '@/components/shell/Shell';
import { AuthGate } from '@/features/auth-gate';
import { MandatorGate } from '@/features/mandator-gate';
import { UserBox } from '@/features/user-box';
import GlobalProvider from '@/global-provider';
import '@/styles/index.css';

import Nav from './nav';
import Routes from './routes';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const App = () => {
  const navigate = useNavigate();

  return (
    <AuthGate>
      <MandatorGate>
        <Shell>
          {{
            sidebar: (
              <Flex direction="column" height="100%">
                <Nav />
                <Divider size="S" />
                <View flex="0 0 fit-content" padding={'size-50'}>
                  <UserBox />
                </View>
              </Flex>
            ),
            content: <Routes />,
          }}
        </Shell>
      </MandatorGate>
    </AuthGate>
  );
};

root.render(
  <GlobalProvider>
    <App />
  </GlobalProvider>
);
